import { Assignment as NotesIcon } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import langDictionary from 'app-strings';
import { usePermissions } from 'permissions/utils';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { ResidentsListResponse } from 'types/residents';

import { ActivityIndicator } from 'components/Shared/ActivityIndicator';
import { pxToRem } from 'components/theme/typography';
import { AVATAR_CONTAINER_WIDTH, BUILDING_STATUS_WIDTH } from 'constants/residents';
import ResidentPhotoContainer from 'pages/Residents/components/ResidentPhotoContainer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    done: {
        color: theme.palette.app.green.main,
        backgroundColor: alpha(theme.palette.app.green.main, 0.08),
    },
    pending: {
        color: theme.palette.grey[800],
        backgroundColor: alpha(theme.palette.grey[600], 0.08),
    },
    inBuilding: {
        color: theme.palette.app.green.main,
    },
    outOfBuilding: {
        color: theme.palette.grey[500],
    },
}));

const RowStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        py: pxToRem(16),
        display: 'flex',
        alignItems: 'center',
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
        width: '100%',
        pl: pxToRem(24),
        '&:last-of-type': {
            borderBottom: 'none',
        },
    })
);

const ResidentDetailsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: `calc(100% - ${pxToRem(AVATAR_CONTAINER_WIDTH + BUILDING_STATUS_WIDTH)})`,
    })
);

const ResidentName = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        lineHeight: 1.5,
        fontSize: { md: pxToRem(17), lg: pxToRem(18) },
        textAlign: 'left',
    })
);

const NotesIconStyle = styled(NotesIcon)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(4),
        color: theme.palette.app.green.main,
        fontSize: pxToRem(14),
    })
);

const TasksAndRoom = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
    })
);

const TasksCounter = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mr: pxToRem(4),
        px: pxToRem(8),
        py: pxToRem(4),
        fontSize: { xs: pxToRem(11), md: pxToRem(13), lg: pxToRem(15) },
        fontWeight: 700,
        lineHeight: 1.3,
        borderRadius: pxToRem(4),
    })
);

const RoomNumber = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        color: theme.palette.app.orange.main,
        lineHeight: 1.5,
        fontSize: { md: pxToRem(16), lg: pxToRem(18) },
    })
);

const BuildingStatusContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        justifyContent: 'flex-end',
        width: pxToRem(BUILDING_STATUS_WIDTH),
    })
);

type Props = {
    resident: ResidentsListResponse;
    branchId: number;
};

const ResidentRowData = (props: Props) => {
    const navigate = useNavigate();
    const classes = useStyles();
    const {
        resident: {
            residentId,
            firstName,
            lastName,
            photo,
            roomNumber,
            buildingStatus,
            totalTasks,
            totalTasksDone,
            totalShiftNotes,
            hasShower,
            showerComplete,
            hasLaundry,
            laundryComplete,
            isOnHospice,
        },
    } = props;
    const { residents: residentsDictionary } = langDictionary;

    const residentName = `${firstName} ${lastName}`;
    const residentLocation = `${residentsDictionary.room} ${roomNumber ?? 'not set'}`;

    const areAllTasksCompleted = totalTasks === totalTasksDone;

    const hasPermission = usePermissions();

    const goToResidentDetails = () => {
        let url = `/residents/${residentId}`;
        if (hasPermission('Community', 'read-resident-deep-dive')) url += '/deep-dive';
        navigate(url);
    };

    return (
        <RowStyle
            key={residentId}
            onClick={goToResidentDetails}
            sx={{
                backgroundColor: areAllTasksCompleted ? 'activityIndicator.tasksCompletedBackground' : undefined,
            }}
        >
            <ResidentPhotoContainer
                residentName={residentName}
                residentPhoto={photo}
                totalTasks={totalTasks}
                totalTasksDone={totalTasksDone}
            />
            <ResidentDetailsContainer>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: pxToRem(8),
                    }}
                >
                    {hasShower && (
                        <ActivityIndicator
                            needsShower={hasShower}
                            showerComplete={showerComplete}
                            isOnHospice={isOnHospice}
                        />
                    )}
                    {hasLaundry && <ActivityIndicator laundryComplete={laundryComplete} />}
                </Box>
                <ResidentName variant="h3">
                    {residentName}
                    {totalShiftNotes > 0 && <NotesIconStyle />}
                </ResidentName>
                <TasksAndRoom>
                    <TasksCounter variant="caption" className={areAllTasksCompleted ? classes.done : classes.pending}>
                        {totalTasksDone}/{totalTasks}
                    </TasksCounter>
                    <RoomNumber variant="h5">{residentLocation ?? 'Apt not set'}</RoomNumber>
                </TasksAndRoom>
            </ResidentDetailsContainer>
            <BuildingStatusContainer>
                <Typography
                    variant="caption"
                    className={buildingStatus === 'In Building' ? classes.inBuilding : classes.outOfBuilding}
                >
                    {buildingStatus}
                </Typography>
            </BuildingStatusContainer>
        </RowStyle>
    );
};

export default ResidentRowData;
