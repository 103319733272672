import { CancelOutlined, Close as CloseIcon } from '@mui/icons-material';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import React, { ReactNode, useMemo } from 'react';

import { pxToRem } from 'components/theme/typography';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useStyles = makeStyles((theme: any) => ({
    footerDivider: {
        borderTop: `1px solid ${theme.palette.grey[300]}`,
    },
}));

const DialogTitleStyle = styled(DialogTitle)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        alignItems: 'center',
        width: `calc(100% - ${pxToRem(48)})`,
        marginBottom: pxToRem(12),
    })
);

const IconButtonStyle = styled(IconButton)(({ theme }) =>
    theme.unstable_sx({
        ml: pxToRem(8),
        p: pxToRem(8),
        position: 'absolute',
        right: pxToRem(8),
        color: theme.palette.grey[600],
        '& .MuiSvgIcon-root': {
            fontSize: pxToRem(16),
        },
    })
);

type Props = {
    actions?: ReactNode;
    className?: string;
    closeable?: boolean;
    content: ReactNode;
    height?: number;
    id?: string;
    maxHeight?: number | string;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    open: boolean;
    showDividers?: boolean;
    showFooterDivider?: boolean;
    subtitle?: ReactNode;
    title?: string;
    width?: number | string;
    onClose: () => void;
};

// eslint-disable-next-line import/prefer-default-export
export const CustomMobileDialog = (props: Props) => {
    const classes = useStyles();
    const {
        actions,
        className,
        closeable,
        content,
        height,
        id,
        maxHeight,
        maxWidth = 'md',
        open,
        showDividers,
        showFooterDivider,
        subtitle,
        title,
        width,
        onClose,
    } = props;
    const isMobile = useMediaQuery('(max-width:1024px)');

    const dialogStyles = {
        '& .MuiDialog-paperScrollPaper': {
            height: height || 'auto',
            width: width || 'auto',
            maxHeight: maxHeight || 'auto',
        },
    };

    const mobileDialogStyles = {
        '& .MuiDialog-container': {
            alignItems: 'flex-end',
        },
        '& .MuiDialog-paperScrollPaper': {
            height: height || 'auto',
            width: width || 'auto',
            maxHeight: maxHeight || 'auto',
        },
        '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper': {
            marginTop: 0,
            marginBottom: 0,
            marginRight: 0,
            marginLeft: 0,
            borderRadius: 0,
            padding: pxToRem(24),
            maxHeight: `calc(100% - ${pxToRem(30)})`,
        },
        '& .MuiDialogContent-root .MuiTypography-root': {
            fontSize: pxToRem(14),
        },
        '& .MuiDialog-container .MuiPaper-root.MuiDialog-paper .MuiDialogContent-root': {
            padding: 0,
        },
    };

    const handleOnClose = (_event, reason) => {
        if (reason && reason === 'backdropClick') {
            return;
        }

        onClose();
    };

    const cancelButtonStyles = useMemo(
        () => ({
            height: pxToRem(34),
            width: pxToRem(34),
        }),
        []
    );

    if (!open) {
        return null;
    }

    if (isMobile) {
        return (
            <Dialog
                id={id}
                className={className}
                maxWidth={maxWidth}
                open={open}
                onClose={handleOnClose}
                sx={mobileDialogStyles}
                scroll="paper"
            >
                {!!title && (
                    <Box
                        sx={{
                            display: 'flex',
                            marginBottom: pxToRem(24),
                            gap: pxToRem(8),
                        }}
                    >
                        <Typography
                            sx={{
                                flexGrow: 1,
                                fontWeight: 700,
                                fontSize: pxToRem(16),
                                margin: 'auto',
                                textAlign: 'left',
                            }}
                        >
                            {title}
                        </Typography>
                        {closeable && (
                            <Box onClick={onClose}>
                                <CancelOutlined style={cancelButtonStyles} />
                            </Box>
                        )}
                    </Box>
                )}
                {!!subtitle && <>{subtitle}</>}
                <DialogContent dividers={showDividers}>{content}</DialogContent>
                {!!actions && (
                    <DialogActions className={showFooterDivider ? classes.footerDivider : ''}>{actions}</DialogActions>
                )}
            </Dialog>
        );
    }

    return (
        <Dialog
            id={id}
            className={className}
            maxWidth={maxWidth}
            open={open}
            onClose={handleOnClose}
            sx={dialogStyles}
            scroll="paper"
        >
            {!!title && (
                <DialogTitleStyle>
                    {title}
                    {closeable && (
                        <IconButtonStyle onClick={onClose}>
                            <CloseIcon />
                        </IconButtonStyle>
                    )}
                </DialogTitleStyle>
            )}
            {!!subtitle && <>{subtitle}</>}
            <DialogContent dividers={showDividers}>{content}</DialogContent>
            {!!actions && (
                <DialogActions className={showFooterDivider ? classes.footerDivider : ''}>{actions}</DialogActions>
            )}
        </Dialog>
    );
};
