import { Box, Typography } from '@mui/material';
import langDictionary from 'app-strings';
import React from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { ActivityIndicator } from 'components/Shared/ActivityIndicator';
import { pxToRem } from 'components/theme/typography';

type Props = {
    firstName: string;
    lastName: string;
    roomNumber: string;
    needsShower: boolean;
    needsLaundry: boolean;
    isOnHospice: boolean;
};

export const ResidentDetails = (props: Props) => {
    const { firstName, lastName, roomNumber, needsShower, needsLaundry, isOnHospice } = props;

    const { room: roomTxt } = langDictionary.home;

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    gap: pxToRem(8),
                }}
            >
                {needsShower && <ActivityIndicator needsShower={needsShower} isOnHospice={isOnHospice} />}
                {needsLaundry && <ActivityIndicator />}
            </Box>
            <Typography
                sx={{
                    textAlign: 'left',
                    fontWeight: 700,
                }}
            >
                {`${firstName} ${lastName}`}
            </Typography>
            <Typography
                sx={{
                    color: 'orange.main',
                    fontWeight: 700,
                }}
            >
                {`${roomTxt} ${roomNumber ?? 'not set'}`}
            </Typography>
        </Box>
    );
};
