import { SvgIconComponent } from '@mui/icons-material';
import {
    Avatar,
    Box,
    Link,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
    styled,
} from '@mui/material';
import { getHours } from 'date-fns';
import { Calendar, Graph, Icon } from 'iconsax-react';
import { ResourceActions, ResourceType } from 'permissions/constants';
import { usePermissions } from 'permissions/utils';
import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { IconType } from 'react-icons';
import { FaCloudDownloadAlt, FaUserCircle } from 'react-icons/fa';
import { PiUserListBold } from 'react-icons/pi';
import { useNavigate } from 'react-router';
import { useSoftMatch } from 'router';

import { OperationsIcon } from 'components/Svg';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { ModernHomeIcon } from 'components/Svg/ModernHomeIcon';
import { ResidentsIcon } from 'components/Svg/ResidentsIcon';
import { TaskListIcon } from 'components/Svg/TaskListIcon';
import { pxToRem } from 'components/theme/typography';
import { useAppDispatch } from 'constants/redux';
import { isPostHogEnabled } from 'helpers/PostHogHandler';
import { toggleMobileDrawer } from 'redux/actions/ui';

export type NavigationOption = {
    title: string;
    path: string;
    icon?: Icon | IconType | SvgIconComponent;
    permission?: { type: ResourceType; action: ResourceActions<ResourceType> };
    featureFlag?: string; // Open by default if PostHog is not set up (e.g. in dev)
};

export const DESKTOP_HEADER_HEIGHT = 80;
export const DESKTOP_DRAWER_WIDTH = 280;
export const MOBILE_HEADER_HEIGHT = 48;
export const MOBILE_DRAWER_WIDTH = 240;
export const MOBILE_BOTTOM_NAVIGATION_HEIGHT = 56;

export const getDrawerGreeting = () => {
    const currentHour = getHours(new Date());
    if (currentHour < 12) return 'Good Morning';
    if (currentHour < 18) return 'Good Afternoon';
    return 'Good Evening';
};

export const HeaderTitle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexGrow: 1,
        flexWrap: 'wrap',
    })
) as typeof Typography;

export const DrawerGreetingContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        color: { xs: 'white', lg: 'black' },
        bgcolor: { xs: 'app.green.main', lg: 'transparent' },
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: pxToRem(16),
        p: pxToRem(16),
    })
);

export const DrawerGreetingBox = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    })
);

export const DrawerAvatar = styled(Avatar)(({ theme }) =>
    theme.unstable_sx({
        bgcolor: { xs: 'white', lg: 'grey.200' },
        color: 'black',
        width: pxToRem(40),
        height: pxToRem(40),
    })
);

export const DrawerList = styled(List)(({ theme }) =>
    theme.unstable_sx({
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: { xs: 'space-between', lg: 'flex-start' },
    })
);

export const DrawerListItemIcon = styled(ListItemIcon)(({ theme }) =>
    theme.unstable_sx({
        color: 'inherit',
        minWidth: pxToRem(40),
    })
);

export const DrawerSupportListItem = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        color: 'grey.500',
        fontSize: pxToRem(14),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: { lg: '100%' },
        mx: pxToRem(16),
        mb: pxToRem(10),
    })
);

export const DrawerPrivacyLink = styled(Link)(({ theme }) =>
    theme.unstable_sx({
        color: 'grey.500',
        textDecoration: 'underline',
        '&:hover': {
            color: 'grey.700',
            fontWeight: 'normal',
            textDecoration: 'underline',
        },
    })
);

export const DrawerOption = ({ title, path, icon: Icon }: NavigationOption) => {
    const navigate = useNavigate();
    const isMatch = useSoftMatch(path);

    const dispatch = useAppDispatch();

    const handleItemClick = () => {
        dispatch(toggleMobileDrawer());
        navigate(path, { replace: true });
    };

    return (
        <ListItem key={title} onClick={handleItemClick} selected={isMatch}>
            <ListItemButton>
                <DrawerListItemIcon>
                    {Icon && <Icon variant={isMatch ? 'Bold' : 'Linear'} size={24} />}
                </DrawerListItemIcon>
                <ListItemText primary={title} />
            </ListItemButton>
        </ListItem>
    );
};

export const DrawerOptions = ({ options }: { options: NavigationOption[] }) => {
    const hasPermission = usePermissions();
    const posthog = usePostHog();

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: { xs: '100%', lg: 'auto' } }}>
            {options.map((option) => {
                const { permission, featureFlag } = option;
                if (permission && !hasPermission(permission.type, permission.action)) return null;
                if (isPostHogEnabled && featureFlag && !posthog.isFeatureEnabled(featureFlag)) return null;
                return <DrawerOption key={option.title} {...option} />;
            })}
        </Box>
    );
};

const MenuModernHomeIcon = () => <ModernHomeIcon size={24} viewBox="0 0 24 24" />;
const MenuTaskListIcon = () => <TaskListIcon size={24} viewBox="0 0 24 24" />;
const MenuResidentIcon = () => <ResidentsIcon size={24} viewBox="0 0 24 24" />;
const MenuGoldCoin = () => <GoldCoin size={20} viewBox="0 0 20 20" />;

export const BOTTOM_NAVIGATION_OPTIONS: NavigationOption[] = [
    {
        title: 'Home',
        path: '/home',
        icon: MenuModernHomeIcon,
        permission: { type: 'Community', action: 'undertake-resident-action' },
    },
    {
        title: 'Residents',
        path: '/residents',
        icon: MenuResidentIcon,
        permission: { type: 'Community', action: 'undertake-resident-action' },
    },
    {
        title: 'My Rewards',
        path: '/rewards',
        icon: MenuGoldCoin,
        permission: { type: 'Community', action: 'update-reward' },
    },
    {
        title: 'Operations & Risks',
        path: '/operations',
        icon: OperationsIcon,
        permission: { type: 'Community', action: 'read-operations-dashboard' },
    },
    {
        title: 'Care Documentation',
        path: '/care-documentation',
        icon: MenuTaskListIcon,
        permission: { type: 'Community', action: 'read-all-resident-actions' },
    },
    {
        title: 'Resident Deep Dive',
        path: '/residents',
        icon: MenuResidentIcon,
        permission: { type: 'Community', action: 'read-resident-deep-dive' },
    },
];

export const DRAWER_OPTIONS: NavigationOption[] = [
    {
        title: 'Profile',
        path: '/profile',
        icon: FaUserCircle,
    },
    {
        title: 'Export Data',
        path: '/export-data',
        icon: FaCloudDownloadAlt,
        permission: { type: 'Community', action: 'read-all-resident-actions' },
    },
];
