import { api } from 'api';
import langDictionary from 'app-strings';

import { ErrorProps } from 'types/messages';
import { UserResponse, UsersReadParams } from 'types/users';

import { formatApiParams } from 'lib/common';
import actionTypes from 'redux/actionTypes';
import loading from 'redux/actions/loading';
import { handleError } from 'redux/actions/messages';
import 'redux/reducers/users';

/**
 * Fetches the list of Users based on the provided params.
 * @param jsonParams the JSON object with the params for fetching the Users list.
 */
export const readUsers =
    (jsonParams?: UsersReadParams, actionType = actionTypes.USER_READ_LIST) =>
    async (dispatch, getState) => {
        try {
            // Identify if the current alert message (if any displayed) should be hidden.
            const shouldHideAlert = actionType === actionTypes.USER_READ_LIST;

            // Enable the loading process.
            dispatch(loading(actionTypes.USER_LOADING, true, shouldHideAlert));

            // Get the token.
            const { token } = getState().session.sessionData;

            // Encode the params to be sent to the API.
            const params = jsonParams ? formatApiParams(jsonParams) : undefined;

            // Get the list of the Users that match with the provided params.
            const serverResponse: UserResponse[] = (
                await api.get('users/all', {
                    headers: {
                        authorization: token,
                    },
                    params,
                })
            ).data.response;

            // Updates the State in Redux.
            dispatch({
                type: actionType,
                payload: serverResponse,
            });
        } catch (error) {
            const errorProps: ErrorProps = {
                error,
                consoleMessage: langDictionary.error.generalError,
                alertMessage: langDictionary.error.generalError,
            };

            dispatch(handleError(errorProps));
        } finally {
            // Disable the loading process.
            dispatch(loading(actionTypes.USER_LOADING, false));
        }
    };

/**
 * Fetches current user.
 */
export const readUser =
    (skipLoading = false) =>
    async (dispatch, getState) => {
        try {
            if (!skipLoading) {
                // Enable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, true));
            }

            // Get the token.
            const { token } = getState().session.sessionData;

            // Get the data for the requested user.
            const serverResponse: UserResponse = (
                await api.get('users', {
                    headers: {
                        authorization: token,
                    },
                })
            ).data.response;

            // Updates the State in Redux.
            dispatch({
                type: actionTypes.USER_READ,
                payload: serverResponse,
            });
        } catch (error) {
            const errorCode = error.response.data.code;
            const errorShort = error.response.data.short;
            const errorMessage =
                errorCode === 400 && errorShort === 'missing'
                    ? langDictionary.error.missingUserId
                    : langDictionary.error.userNotFound;

            const errorProps: ErrorProps = {
                error,
                consoleMessage: errorMessage,
                alertMessage: errorMessage,
            };

            dispatch(handleError(errorProps));
        } finally {
            if (!skipLoading) {
                // Disable the loading process.
                dispatch(loading(actionTypes.USER_LOADING, false));
            }
        }
    };
