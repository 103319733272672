import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import * as LiveUpdates from '@capacitor/live-updates';
import React, { useEffect, useRef, useState } from 'react';

import Loading from 'components/Shared/Loading';

const LoadingWrapper = ({ children }: { children: React.ReactNode }) => {
    const isNativePlatform = Capacitor.isNativePlatform();

    const [isUpdateAvailable, _setIsUpdateAvailable] = useState<boolean | null>(null);
    const isUpdateAvailableRef = useRef(isUpdateAvailable); // Ref is needed to access the state inside the 'resume' listener below

    const setIsUpdateAvailable = (data) => {
        isUpdateAvailableRef.current = data;
        _setIsUpdateAvailable(data);
    };

    useEffect(() => {
        const run = async () => {
            if (!isNativePlatform || process.env.REACT_APP_OTA_ENABLED === 'false') {
                setIsUpdateAvailable(false);
                return;
            }

            // Force update the app when it is opened
            const result = await LiveUpdates.sync();
            setIsUpdateAvailable(result.activeApplicationPathChanged);
            if (result.activeApplicationPathChanged) await LiveUpdates.reload();

            // People often keep apps minimized without ever closing them
            // This makes sure we keep looking for and applying OTA updates
            CapacitorApp.addListener('resume', async () => {
                if (isUpdateAvailableRef.current) {
                    await LiveUpdates.reload();
                } else {
                    const newResult = await LiveUpdates.sync();
                    setIsUpdateAvailable(newResult.activeApplicationPathChanged);
                }
            });
        };

        run();
    }, []);

    if (isNativePlatform && isUpdateAvailable === null) {
        return <Loading label="Checking for updates..." />;
    }

    return children;
};

export default LoadingWrapper;
