import { Capacitor } from '@capacitor/core';
import { QueryClientProvider } from '@tanstack/react-query';
import { OnboardingProvider } from 'contexts/OnboardingContext';
import { AbilityLoader } from 'permissions/ability';
import React from 'react';
import { Outlet } from 'react-router';

import AppUrlListener from '../helpers/AppUrlListener';
import LoadingWrapper from '../helpers/LoadingWrapper';
import { queryClient } from 'api/queries';
import PageTitleHandler from 'components/Layout/PageTitleHandler';
import Messages from 'components/Shared/Messages';
import NotificationsHandler from 'helpers/NotificationsHandler';
import { PostHogHandler } from 'helpers/PostHogHandler';
import PostHogPageViewTracker from 'router/components/PostHogPageViewTracker';

import { GeolocationProvider } from '../contexts/GeolocationContext';

const App = () => (
    <>
        <PostHogPageViewTracker />
        <GeolocationProvider>
            <QueryClientProvider client={queryClient}>
                <OnboardingProvider>
                    <AbilityLoader>
                        {Capacitor.isNativePlatform() && (
                            <>
                                <NotificationsHandler />
                                <AppUrlListener />
                            </>
                        )}
                        <PostHogHandler />
                        <Messages />
                        <LoadingWrapper>
                            <PageTitleHandler />
                            <Outlet />
                        </LoadingWrapper>
                    </AbilityLoader>
                </OnboardingProvider>
            </QueryClientProvider>
        </GeolocationProvider>
    </>
);

export default App;
