import { Box, Button } from '@mui/material';
import React from 'react';

import { CustomMobileDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onDelete: () => void;
};

const DeleteConfirmationDialog = (props: Props) => {
    const { isOpen, onClose, onDelete } = props;

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            width="100%"
            onClose={onClose}
            title="Are you sure you want to delete this note?"
            content={
                <Box
                    sx={{
                        mt: pxToRem(8),
                        display: 'grid',
                        gridTemplateColumns: '40% 1fr',
                        gap: pxToRem(12),
                    }}
                >
                    <Button
                        variant="outlined"
                        color="error"
                        onClick={onClose}
                        sx={{
                            width: '100%',
                            p: pxToRem(12),
                            fontSize: pxToRem(14),
                        }}
                    >
                        No
                    </Button>
                    <Button
                        color="primary"
                        onClick={onDelete}
                        sx={{
                            width: '100%',
                            p: pxToRem(12),
                            fontSize: pxToRem(14),
                        }}
                    >
                        Yes
                    </Button>
                </Box>
            }
        />
    );
};

export default DeleteConfirmationDialog;
