import { useMutation } from '@tanstack/react-query';
import { api } from 'api';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useAppDispatch } from 'constants/redux';
import { throwError } from 'redux/actions/messages';

export const useSignupMutation = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    type Params = {
        firstName: string;
        lastName: string;
        username: string;
        phoneNumber: string;
        isPinPhoneNumber: boolean;
        email?: string;
    };

    return useMutation({
        mutationFn: async (rawParams: Params) => {
            const { isPinPhoneNumber, ...params } = rawParams;
            const { data } = await api.post(`/users`, params);

            return { phoneNumber: params.phoneNumber, isPinPhoneNumber, sessionData: data.response };
        },
        onSuccess: ({ phoneNumber, isPinPhoneNumber }) => {
            if (!isPinPhoneNumber) {
                const encodedPhoneNumber = encodeURIComponent(phoneNumber);
                navigate(`/signup/verify?phoneNumber=${encodedPhoneNumber}&optional=true`, { replace: true });
            }
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
