import { api } from 'api';

import { ExportTaskRecordsCsvParams } from 'types/dailyTasks';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from 'lib/common';

export const useExportTaskRecordsCsvQuery = ({ type, ...jsonParams }: ExportTaskRecordsCsvParams) => {
    const token = useToken();
    return useErrorHandledQuery(
        ['export-task-records-csv', jsonParams],
        async () => {
            const { data } = await api.get<ArrayBuffer>(`/tasks/export-csv/${type}`, {
                headers: { Authorization: token },
                params: formatApiParams(jsonParams),
                responseType: 'blob',
            });
            return data;
        },
        { enabled: false }
    );
};
