import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { usePermissionsQuery } from 'api/queries/permissions';

import { ResourceActions, ResourceType } from './constants';

export const usePermissions = () => {
    const {
        sessionData: { companyId, regionId, branchId },
    } = useSelector((state: ReduxStore) => state.session);

    const { data: permissions } = usePermissionsQuery();

    return <T extends ResourceType>(type: T, action: ResourceActions<T>, key?: number) => {
        if (!permissions) return null;

        let autoPopulatedKey = key;

        if (!autoPopulatedKey) {
            if (type === 'Company') autoPopulatedKey = companyId;
            else if (type === 'Region') autoPopulatedKey = regionId;
            else if (type === 'Community') autoPopulatedKey = branchId;
        }

        const resource = autoPopulatedKey ? `${type}:${autoPopulatedKey}` : type;
        return permissions[resource]?.[action];
    };
};
