import { queryClient } from '.';
import { useMutation } from '@tanstack/react-query';
import { api } from 'api';

import { UserRewardsDetailsResponse, UserRewardsHistoryResponse, UserRewardsSummaryResponse } from 'types/myRewards';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { useToken } from 'lib/common';

export const useRewardsSummaryQuery = (userId?: number, branchId?: number) => {
    const token = useToken();

    return useErrorHandledQuery(
        ['rewards', userId, 'summary'],
        async (): Promise<UserRewardsSummaryResponse> =>
            (
                await api.get('/rewards/user/summary', {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!userId && !!branchId, staleTime: 300000 }
    );
};

export const useRewardsDetailsQuery = (userId?: number, branchId?: number) => {
    const token = useToken();

    return useErrorHandledQuery(
        ['rewards', userId, 'details'],
        async (): Promise<UserRewardsDetailsResponse> =>
            (
                await api.get('/rewards/user/details', {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!userId && !!branchId, staleTime: 300000 }
    );
};

export const useRewardsHistoryQuery = (userId?: number, branchId?: number) => {
    const token = useToken();

    return useErrorHandledQuery(
        ['rewards', userId, 'history'],
        async (): Promise<UserRewardsHistoryResponse> =>
            (
                await api.get('/rewards/user/history', {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                })
            ).data.response,
        { enabled: !!userId && !!branchId, staleTime: 300000 }
    );
};

export const useClaimPointsMutation = (userId: number, branchId: number) => {
    const token = useToken();

    return useMutation({
        mutationFn: async () => {
            await api.post(
                '/rewards/user/claim-points',
                {},
                {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                }
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['rewards', userId],
                exact: false,
                refetchType: 'active',
            });
        },
    });
};

export const useRedeemPointsMutation = (userId: number, branchId: number) => {
    const token = useToken();

    return useMutation({
        mutationFn: async () => {
            await api.post(
                '/rewards/user/redeem-reward',
                {},
                {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                }
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['rewards', userId],
                exact: false,
                refetchType: 'active',
            });
        },
    });
};

export const useCheckInMutation = (userId: number, branchId: number) => {
    const token = useToken();

    return useMutation({
        mutationFn: async () => {
            await api.post(
                '/rewards/user/check-in',
                {},
                {
                    headers: {
                        authorization: token,
                    },
                    params: { branch_id: branchId }, // For permission checking
                }
            );
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries({
                queryKey: ['rewards', userId],
                exact: false,
                refetchType: 'active',
            });
        },
    });
};
