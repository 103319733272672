import { Typography } from '@mui/material';
import langDictionary from 'app-strings';
import React from 'react';

import { CustomDialog } from 'components/Custom';

type Props = {
    isOpen: boolean;
    taskNote: string | undefined;
    onClose: () => void;
};

const TaskNotesDialog = (props: Props) => {
    const { isOpen, taskNote, onClose } = props;

    return (
        <CustomDialog
            closeable
            open={isOpen}
            title={langDictionary.home.taskNotes}
            width="100%"
            onClose={onClose}
            content={<Typography variant="body2">{taskNote}</Typography>}
        />
    );
};

export default TaskNotesDialog;
