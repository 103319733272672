import { Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';

import { ChangeOfConditionSectionDetail } from 'types/operationsDashboard';

type Props = {
    rows: ChangeOfConditionSectionDetail[];
};

export const ChangeOfConditionCollapsibleComponent = ({ rows }: Props) => {
    function renderRow(item: ChangeOfConditionSectionDetail) {
        return (
            <TableRow key={[item.type, item.value].join('')}>
                <TableCell align="left" sx={item.isNewEvent ? { color: '#BF2828' } : undefined}>
                    {item.type}
                </TableCell>
                <TableCell align="left" sx={item.isNewEvent ? { color: '#BF2828', fontWeight: 600 } : undefined}>
                    {item.value}
                </TableCell>
            </TableRow>
        );
    }

    return (
        <TableContainer>
            <Table aria-label="details row" sx={{ tableLayout: 'fixed' }}>
                <TableBody>{rows.map(renderRow)}</TableBody>
            </Table>
        </TableContainer>
    );
};
