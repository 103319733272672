import { Box, Link, Stack, Typography } from '@mui/material';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';

import { useLoginInitMutation } from 'api/queries/auth/login';
import { useSignupMutation } from 'api/queries/auth/signup';
import AuthButton from 'components/Auth/AuthButton';
import AuthCtaLink from 'components/Auth/AuthCtaLink';
import AuthInput from 'components/Auth/AuthInput';
import AuthTitle from 'components/Auth/AuthTitle';
import BackButton from 'components/Auth/BackButton';
import FormContainer from 'components/Auth/FormContainer';
import MainContainer from 'components/Auth/MainContainer';
import PhoneNumberInput from 'components/Auth/PhoneNumberInput';
import { pxToRem } from 'components/theme/typography';
import { EMAIL_REGEX, REMOVE_SPECIAL_CHARACTERS, REMOVE_WHITE_SPACE } from 'constants/regex';
import { registerSession } from 'lib/auth';
import PageStructure from 'pages/PageStructure';

export type SignupFormValues = {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email?: string;
};

const defaultValues = {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
};

// Used when people don't want to enter a real phone number
const randomizePhoneNumber = (phoneNumber: string) => {
    const randomDigits = Math.floor(Math.random() * 1000000) // Random 6-digit number
        .toString()
        .padStart(6, '0');
    const lastDigits = phoneNumber.slice(-4); // The last 4 digits will become a "pin"
    return `+1555${randomDigits}${lastDigits}`;
};

const removeSpecialCharacters = (name: string) => name.replace(REMOVE_SPECIAL_CHARACTERS, '');

const createUsername = (firstName: string, lastName: string) =>
    `${firstName.replace(REMOVE_WHITE_SPACE, '').toLowerCase()}.${lastName.replace(REMOVE_WHITE_SPACE, '').toLowerCase()}`;

const Signup = () => {
    const {
        handleSubmit,
        control,
        formState: { errors, isValid },
        watch,
    } = useForm<SignupFormValues>({
        defaultValues,
        mode: 'onChange',
        criteriaMode: 'all',
        reValidateMode: 'onChange',
        delayError: 2000,
    });

    const { mutate: signupMutation, isLoading } = useSignupMutation();

    const { mutate: loginInitMutation } = useLoginInitMutation();

    const currentFirstName = removeSpecialCharacters(watch('firstName'));
    const currentLastName = removeSpecialCharacters(watch('lastName'));

    const currentUsername =
        currentFirstName && currentLastName ? createUsername(currentFirstName, currentLastName) : '';

    const navigate = useNavigate();

    const onSubmit = (data: SignupFormValues) => {
        const { phoneNumber } = data;

        // Workaround for when people don't want to enter a real phone number
        const isPinPhoneNumber = phoneNumber.length === 6; // +1XXXX
        const formattedPhoneNumber = isPinPhoneNumber ? randomizePhoneNumber(phoneNumber) : phoneNumber;

        signupMutation(
            {
                firstName: data.firstName,
                lastName: data.lastName,
                username: currentUsername,
                phoneNumber: formattedPhoneNumber,
                isPinPhoneNumber,
                ...(data.email && { email: data.email }),
            },
            {
                onSuccess: ({ sessionData }) => {
                    if (!isPinPhoneNumber) {
                        registerSession({
                            // Setting isSignedIn to false to show the verification code screen
                            isSignedIn: false,
                            sessionData,
                        });

                        loginInitMutation({
                            phoneNumber: data.phoneNumber,
                        });
                    } else {
                        registerSession({
                            isSignedIn: true, // Without a phone number, we can't validate, so we just say they're signed in
                            sessionData,
                        });

                        navigate('/signup/verify', { replace: true });
                    }
                },
            }
        );
    };

    return (
        <PageStructure>
            <MainContainer>
                <FormContainer>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Box
                            sx={{
                                pt: 'env(safe-area-inset-top)',
                            }}
                        >
                            <BackButton returnToUrl={'/login'} />
                            <Box
                                sx={{
                                    mt: {
                                        xs: pxToRem(20),
                                        lg: pxToRem(12),
                                    },
                                    mb: {
                                        xs: pxToRem(36),
                                    },
                                }}
                            >
                                <AuthTitle title="User Information" subtitle="Set your caregiver profile" />
                            </Box>
                            <Stack spacing={2} sx={{ mb: pxToRem(44) }}>
                                <AuthInput
                                    name="firstName"
                                    label="First Name"
                                    control={control}
                                    placeholder="Enter first name"
                                    type="text"
                                    error={errors.firstName}
                                    rules={{
                                        required: 'First Name is required',
                                        minLength: {
                                            value: 2,
                                            message: 'First Name must be at least 2 characters',
                                        },
                                    }}
                                    capitalize
                                />
                                <Box>
                                    <AuthInput
                                        name="lastName"
                                        label="Last Name"
                                        control={control}
                                        placeholder="Enter last name"
                                        type="text"
                                        error={errors.lastName}
                                        rules={{
                                            required: 'Last Name is required',
                                            minLength: {
                                                value: 2,
                                                message: 'Last Name must be at least 2 characters',
                                            },
                                        }}
                                        capitalize
                                    />
                                    {currentUsername && !errors.lastName && (
                                        <Box
                                            sx={{
                                                color: '#FA9C7A',
                                                borderRadius: `0 0 ${pxToRem(8)} ${pxToRem(8)}`,
                                                px: pxToRem(16),
                                                pt: pxToRem(10),
                                                pb: pxToRem(4),
                                                bgcolor: '#FEE7DF',
                                                fontSize: pxToRem(14),
                                                lineHeight: 1.45,
                                                marginTop: pxToRem(-6),
                                            }}
                                        >
                                            Your username will be{' '}
                                            <Box
                                                component="span"
                                                sx={{
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {currentUsername}
                                            </Box>
                                        </Box>
                                    )}
                                </Box>
                                <Box>
                                    <PhoneNumberInput
                                        name="phoneNumber"
                                        label="Phone Number"
                                        control={control}
                                        placeholder="(555) 555-1234"
                                        error={errors.phoneNumber?.message}
                                        rules={{
                                            required: 'Phone Number is required',
                                            minLength: {
                                                value: 4,
                                                // Pretend we want a full phone number, even if it's fake or a "pin"
                                                message: 'Phone Number must be 10 characters long',
                                            },
                                        }}
                                    />
                                    <Typography
                                        sx={{
                                            color: '#6F6F79',
                                            fontSize: { xs: pxToRem(14) },
                                            lineHeight: { xs: 1.45 },
                                            marginTop: pxToRem(4),
                                        }}
                                    >
                                        We will send you a verification code.
                                    </Typography>
                                </Box>
                                <AuthInput
                                    name="email"
                                    label="Email (optional)"
                                    control={control}
                                    placeholder="Enter email"
                                    type="text"
                                    error={errors.email}
                                    rules={{
                                        pattern: {
                                            value: EMAIL_REGEX,
                                            message: 'Invalid email format',
                                        },
                                    }}
                                />
                            </Stack>
                        </Box>
                        <Box
                            sx={{
                                display: 'grid',
                                gap: pxToRem(12),
                                pb: { xs: pxToRem(30), lg: 0 },
                            }}
                        >
                            <AuthCtaLink
                                question="By clicking Sign up, I agree to the"
                                linkLabel="AllieHealth Privacy Policy"
                                linkUrl="privacy-policy"
                                isLink
                            />

                            <AuthButton
                                label="Caregiver sign up"
                                isDisabled={!isValid}
                                isLoading={isLoading}
                                isSubmit
                            />
                            <Typography
                                sx={{
                                    width: '100%',
                                    textAlign: 'left',
                                    fontSize: {
                                        xs: pxToRem(14),
                                    },
                                    lineHeight: {
                                        xs: 1.45,
                                    },
                                    color: '#2C2D3B',
                                    mt: pxToRem(24),
                                }}
                            >
                                Not a caregiver?{' '}
                                <Link
                                    sx={{ color: 'primary.dark', fontWeight: 600 }}
                                    href="mailto:support@alliehealth.com?subject=Create%20AllieHealth%20Account&body=Hello%2C%0A%0AI%20am%20interested%20in%20creating%20a%20non-caregiver%20account.%20Thank%20you%20for%20your%20assistance.%0A%0AYour%20Name%3A%0AYour%20Position%3A
                                    "
                                >
                                    Contact us to sign up
                                </Link>
                            </Typography>
                        </Box>
                    </form>
                </FormContainer>
            </MainContainer>
        </PageStructure>
    );
};

export default Signup;
