import { Box, Link, Theme, useMediaQuery } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-old';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { pxToRem } from 'components/theme/typography';

import { StripedDataGrid } from './OverallStatsTable';
import { formatDateTime } from './constants';

export type DataRow = {
    id: string;
    residentName: string;
    residentRoomNumber: string;
    [key: string]: string | number;
};

type BranchStatsTableProps = {
    rows: DataRow[];
    dynamicColumns: {
        field: string;
        headerName: string;
    }[];
    renderDateSelector: () => JSX.Element | null;
};

const BranchStatsTable = ({ rows, dynamicColumns, renderDateSelector }: BranchStatsTableProps) => {
    const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'));

    // Create dynamic columns that will be added to the table
    const dynamicCols = dynamicColumns.map(({ field, headerName }) => ({
        field,
        headerName,
        width: isLargeScreen ? 220 : 170,
        renderCell: (params: GridRenderCellParams) => (
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                    lineHeight: 1.5,
                }}
            >
                {field.toLowerCase().includes('date') ? formatDateTime(params.value as string) : params.value}
            </Box>
        ),
    }));

    const residentNameCol = {
        field: 'residentName',
        headerName: 'Resident',
        width: isLargeScreen ? 220 : 200,
        renderCell: ({ row }: GridRenderCellParams<DataRow>) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', gap: pxToRem(4) }}>
                <Link component={RouterLink} to={`/residents/${row.id}/deep-dive`}>
                    <Box
                        component="span"
                        sx={{
                            fontWeight: 500,
                            overflow: 'hidden',
                            textWrap: 'nowrap',
                            textOverflow: 'ellipsis',
                            fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                            lineHeight: 1.5,
                            py: { xs: pxToRem(10), lg: pxToRem(12) },
                        }}
                    >
                        {row.residentName}
                    </Box>
                </Link>
                <Box
                    component="span"
                    sx={{
                        fontWeight: 500,
                        fontSize: { xs: pxToRem(12), lg: pxToRem(14) },
                        lineHeight: 1.45,
                        color: '#6F6F79',
                    }}
                >
                    {`(${row.residentRoomNumber ?? 'Apt not set'})`}
                </Box>
            </Box>
        ),
    };

    const columns: GridColDef[] = [residentNameCol, ...dynamicCols];

    const defaultSortColumn = dynamicColumns.length > 0 ? dynamicColumns[0].field : null;

    return (
        <>
            {renderDateSelector()}
            <StripedDataGrid
                autoHeight
                hideFooter
                rows={rows}
                rowHeight={40}
                columns={columns}
                columnHeaderHeight={40}
                disableRowSelectionOnClick
                disableColumnMenu
                getRowClassName={(params) => (params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd')}
                initialState={{
                    sorting: {
                        sortModel: defaultSortColumn ? [{ field: defaultSortColumn, sort: 'desc' }] : [],
                    },
                }}
                sx={{
                    border: 'none',
                    height: pxToRem(320),
                    '& .MuiDataGrid-columnHeader': {
                        color: '#9B9CA2',
                        fontSize: { xs: pxToRem(12), lg: pxToRem(14) },
                        '&:focus-within': {
                            outline: 'none',
                        },
                    },
                    '& .MuiDataGrid-cell': {
                        fontWeight: 700,
                        fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                    },
                    '& .MuiDataGrid-iconButtonContainer': {
                        visibility: 'visible',
                        ml: { xs: 0, md: pxToRem(6) },
                    },
                    '& .MuiDataGrid-sortIcon': {
                        opacity: '.3 !important',
                    },
                    '& .MuiDataGrid-columnSeparator': {
                        display: 'none',
                    },
                    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                        outline: 'none !important',
                    },
                }}
            />
        </>
    );
};

export default BranchStatsTable;
