import { api } from 'api';

import {
    OperationsBranchStatsParams,
    OperationsBranchStatsResponse,
    OperationsOverallStatsParams,
    OperationsOverallStatsResponse,
} from 'types/operationsDashboard';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from 'lib/common';

export const useOperationsOverallStatsQuery = (jsonParams: OperationsOverallStatsParams) => {
    const { groupBy, companyId, regionId, branchId, careType } = jsonParams;
    const token = useToken();

    return useErrorHandledQuery<OperationsOverallStatsResponse>(
        ['operations-overall-stats', jsonParams],
        async (): Promise<OperationsOverallStatsResponse> => {
            const { data } = await api.get('/analytics/operations/overall-stats', {
                headers: { authorization: token },
                params: {
                    group_by: groupBy,
                    company_id: Number.isInteger(companyId) ? companyId : undefined,
                    region_id: Number.isInteger(regionId) ? regionId : undefined,
                    branch_id: Number.isInteger(branchId) ? branchId : undefined,
                    care_type: careType,
                },
            });
            return data.response;
        },
        { enabled: !!jsonParams && jsonParams.isEnabled, staleTime: 300000 }
    );
};

export const useOperationsBranchStatsQuery = (jsonParams?: OperationsBranchStatsParams) => {
    const params = jsonParams ? formatApiParams(jsonParams) : undefined;
    const token = useToken();

    return useErrorHandledQuery<OperationsBranchStatsResponse>(
        ['operations-community-stats', jsonParams],
        async (): Promise<OperationsBranchStatsResponse> => {
            const { data } = await api.get('/analytics/operations/branch-stats', {
                headers: { authorization: token },
                params,
            });
            return data.response;
        },
        { enabled: !!jsonParams, staleTime: 300000 }
    );
};
