import { get24hPeriodEndingAtEndOfGivenDateUtcShift } from '@alliehealth/utils/dist/shifts';
import { ExpandCircleDown as ExpandCircleDownIcon } from '@mui/icons-material';
import { Box, Divider, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { format, parse, subDays } from 'date-fns';
import momentTz from 'moment-timezone';
import { usePermissions } from 'permissions/utils';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';
import { ResidentShiftNotesResponse } from 'types/residentShiftNotes';

import { useBranchShifts } from 'api/queries/branch';
import {
    useResidentShiftNotesDeleteMutation,
    useResidentShiftNotesQuery,
    useResidentShiftNotesUpdateMutation,
} from 'api/queries/shiftNotes/shiftNotes';
import { DESKTOP_DRAWER_WIDTH } from 'components/Layout/shared';
import DeleteConfirmationDialog from 'components/Shared/DeleteConfirmationDialog';
import { pxToRem } from 'components/theme/typography';
import { getFormattedDateTimeMinusOneMinute } from 'lib/common';
import { getDateInUtc } from 'lib/date';
import ShiftNoteCard from 'pages/Residents/Details/components/ShiftNoteCard';
import ShiftNoteDialog from 'pages/Residents/Details/components/ShiftNoteDialog';

const TitleStyle = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        mb: pxToRem(8),
    })
) as typeof Typography;

const shiftNoteMinWidthPx = 320;

const SectionStyle = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: '100%',
        display: 'grid',
        gridTemplateColumns: {
            xs: '1fr',
            lg: `repeat(auto-fill, minmax(${pxToRem(shiftNoteMinWidthPx)}, 1fr))`,
        },
        columnGap: pxToRem(16),
        transition: 'height 0.15s ease-out',
        overflow: 'hidden',
    })
);

const ExpandCollapseIconContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        position: 'relative',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        cursor: 'pointer',
    })
);

const ExpandCollapseIcon = styled(ExpandCircleDownIcon)(({ theme }) =>
    theme.unstable_sx({
        p: pxToRem(6),
        color: theme.palette.app.green.main,
        backgroundColor: theme.palette.common.white,
        borderRadius: '50%',
        fontSize: pxToRem(32),
        position: 'absolute',
        top: pxToRem(-32),
        boxShadow: `0 0 ${pxToRem(8)} ${alpha(theme.palette.grey[500], 0.5)}`,
    })
);

const DividerStyle = styled(Divider)(({ theme }) =>
    theme.unstable_sx({
        my: pxToRem(16),
    })
);

type Props = {
    residentId: number;
    branchId: number;
};

const ShiftNotes = ({ residentId, branchId }: Props) => {
    const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
    const [isShiftNoteDialogOpen, setIsShiftNoteDialogOpen] = useState<boolean>(false);
    const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false);
    const [currentShiftNoteId, setCurrentShiftNoteId] = useState<number>(0);
    const [currentReport, setCurrentReport] = useState<string>('');
    const [currentShiftNoteCategoryId, setCurrentShiftNoteCategoryId] = useState<number>(0);

    const {
        sessionData: { userId },
        timezone,
    } = useSelector((state: ReduxStore) => state.session);

    const hasPermission = usePermissions();

    const branchShifts = useBranchShifts(branchId);
    const { startPeriodInclusive, endPeriodExclusive } = get24hPeriodEndingAtEndOfGivenDateUtcShift(
        getDateInUtc(new Date()),
        timezone!,
        branchShifts
    );

    const parsedStartPeriodInclusive = parse(startPeriodInclusive, 'yyyy-MM-dd HH:mm:ss', new Date());
    const updatedStartPeriodInclusive = format(subDays(parsedStartPeriodInclusive, 2), 'yyyy-MM-dd HH:mm:ss'); // 3 days back from the end of the current shift (AH-783)
    const updatedEndPeriodExclusive = getFormattedDateTimeMinusOneMinute(endPeriodExclusive);

    const { data: residentShiftNotes } = useResidentShiftNotesQuery({
        residentId,
        branchId,
        startPeriod: updatedStartPeriodInclusive,
        endPeriod: updatedEndPeriodExclusive,
    });

    const { mutate: residentShiftNoteUpdate } = useResidentShiftNotesUpdateMutation(userId);
    const { mutateAsync: residentShiftNoteDelete } = useResidentShiftNotesDeleteMutation(userId);

    const getShiftNoteData = (shiftNote: ResidentShiftNotesResponse) => {
        const {
            residentShiftNoteId: key,
            caregiver,
            shiftNoteCategoryId,
            shiftNoteCategory,
            report,
            userId: caregiverId,
            reportedAtUtc,
        } = shiftNote;
        const elapsedTimeLabel = momentTz.utc(reportedAtUtc).fromNow();

        return {
            caregiver,
            caregiverId,
            categoryId: shiftNoteCategoryId,
            category: shiftNoteCategory,
            elapsedTimeLabel,
            key,
            report,
        };
    };

    const handleCollapsibleSectionToggle = () => {
        setIsCollapsed(!isCollapsed);
    };

    const handleDialogClose = () => {
        setIsShiftNoteDialogOpen(false);
        setIsDeleteDialogOpen(false);
    };

    const handleEdit = (shiftNoteId: number, categoryId: number, reportContent: string) => () => {
        if (!hasPermission('Community', 'update-resident-action')) return;

        setCurrentShiftNoteId(shiftNoteId);
        setCurrentShiftNoteCategoryId(categoryId);
        setCurrentReport(reportContent);
        setIsShiftNoteDialogOpen(true);
    };

    const handleShiftNoteSubmit = async (report: string, newShiftNoteCategory: number) => {
        residentShiftNoteUpdate({
            id: currentShiftNoteId,
            residentId,
            branchId: branchId!,
            userId,
            shiftNoteCategoryId: newShiftNoteCategory,
            report,
        });
    };

    const handleDelete = (shiftNoteId: number) => () => {
        if (!hasPermission('Community', 'update-resident-action')) return;

        setCurrentShiftNoteId(shiftNoteId);
        setIsDeleteDialogOpen(true);
    };

    const handleShiftNoteDelete = async () => {
        await residentShiftNoteDelete({ id: currentShiftNoteId, residentId, branchId: branchId! });
        setIsDeleteDialogOpen(false);
    };

    if (!residentShiftNotes?.length) return null;

    const bodyWidth = window.innerWidth - DESKTOP_DRAWER_WIDTH;
    const shiftNotesToShowCount = isCollapsed
        ? Math.max(1, Math.floor(bodyWidth / shiftNoteMinWidthPx)) // Show as many shift notes as possible in the preview
        : residentShiftNotes.length;
    const shiftNotesToShow = residentShiftNotes.slice(0, shiftNotesToShowCount);

    return (
        <>
            <TitleStyle variant="h2">Shift Notes</TitleStyle>
            <SectionStyle>
                {shiftNotesToShow.map((shiftNote) => {
                    const { caregiver, caregiverId, category, categoryId, elapsedTimeLabel, key, report } =
                        getShiftNoteData(shiftNote);

                    return (
                        <ShiftNoteCard
                            key={key}
                            caregiver={caregiver}
                            caregiverId={caregiverId}
                            category={category}
                            elapsedTimeLabel={elapsedTimeLabel}
                            report={report}
                            onEdit={handleEdit(key, categoryId, report)}
                            onDelete={handleDelete(key)}
                        />
                    );
                })}
            </SectionStyle>
            {residentShiftNotes.length > 1 && (
                <ExpandCollapseIconContainer>
                    <ExpandCollapseIcon
                        onClick={handleCollapsibleSectionToggle}
                        sx={{ transform: isCollapsed ? undefined : 'rotate(180deg)' }}
                    />
                </ExpandCollapseIconContainer>
            )}
            <DividerStyle />
            <ShiftNoteDialog
                isOpen={isShiftNoteDialogOpen}
                reportContent={currentReport}
                shiftNoteCategoryId={currentShiftNoteCategoryId.toString()}
                onClose={handleDialogClose}
                onSubmit={handleShiftNoteSubmit}
            />
            <DeleteConfirmationDialog
                isOpen={isDeleteDialogOpen}
                onClose={handleDialogClose}
                onDelete={handleShiftNoteDelete}
            />
        </>
    );
};

export default ShiftNotes;
