import { UsersState } from 'types/redux';

import { USERS_DEFAULT_STATE } from 'constants/redux';
import actionTypes from 'redux/actionTypes';

const users = (state: UsersState = USERS_DEFAULT_STATE, action) => {
    switch (action.type) {
        case actionTypes.USER_LOADING: {
            return {
                ...state,
                loading: action.payload,
            };
        }
        case actionTypes.USER_READ_LIST: {
            return {
                ...state,
                usersList: action.payload,
            };
        }
        case actionTypes.USER_READ: {
            return {
                ...state,
                user: action.payload,
            };
        }
        case actionTypes.SESSION_SIGN_OUT:
            return {
                ...USERS_DEFAULT_STATE,
            };
        default:
            return state;
    }
};

export default users;
