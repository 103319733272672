import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

const PostHogPageViewTracker = () => {
    const { pathname } = useLocation();
    const posthog = usePostHog();

    useEffect(() => void posthog?.capture('$pageview'), [pathname, posthog]);

    return null;
};

export default PostHogPageViewTracker;
