import { Box, Typography } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

export const Header = () => (
    <Box
        sx={{
            mt: {
                xs: pxToRem(48),
                lg: 0,
            },
            mb: {
                xs: pxToRem(36),
            },
        }}
    >
        <Typography
            sx={{
                fontSize: {
                    xs: pxToRem(24),
                },
                lineHeight: {
                    xs: 1.2,
                },
                fontWeight: 700,
                color: '#2C2D3B',
                mb: { xs: pxToRem(4) },
                textAlign: 'left',
            }}
        >
            You are unsubscribed
        </Typography>
        <Typography
            sx={{
                fontSize: {
                    xs: pxToRem(14),
                },
                lineHeight: {
                    xs: 1.7,
                },
                color: '#6F6F79',
                textAlign: 'left',
            }}
        >
            If you were unsubscribed by accident click the button below to re-subscribe.
        </Typography>
    </Box>
);
