import React, { createContext, useContext, useMemo, useState } from 'react';
import { Step } from 'react-joyride';

type Tour = {
    run: boolean;
    stepIndex: number;
    steps: Step[];
    tourActive: boolean;
};

const initialTourState: Tour = {
    run: false,
    stepIndex: 0,
    steps: [],
    tourActive: false,
};

type OnboardingContextType = {
    tour: Tour;
    updateTour: (newTourState: Partial<Tour>) => void;
};

export const OnboardingContext = createContext<OnboardingContextType>({
    tour: initialTourState,
    updateTour: () => null,
});

OnboardingContext.displayName = 'OnboardingContext';

export const OnboardingProvider = ({ children }) => {
    const [tour, setTour] = useState<Tour>(initialTourState);

    const updateTour = (newTourState: Partial<Tour>) => {
        setTour((prevTour) => ({
            ...prevTour,
            ...newTourState,
        }));
    };

    const value = useMemo(
        () => ({
            tour,
            updateTour,
        }),
        [tour]
    );

    return <OnboardingContext.Provider value={value}>{children}</OnboardingContext.Provider>;
};

export const useOnboardingContext = () => {
    const context = useContext(OnboardingContext);

    if (!context) {
        throw new Error('useOnboardingContext must be used within an OnboardingProvider');
    }

    return context;
};
