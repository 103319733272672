import { AbilityTuple, MongoAbility, MongoQuery, createMongoAbility } from '@casl/ability';
import * as Sentry from '@sentry/react';
import { concat } from 'lodash';
import { ActionResourceSchema, Permit, permitState } from 'permit-fe-sdk';
import React, { createContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ReduxStore } from 'types/redux';

import { useBranchesByRegionQuery } from 'api/queries/companyInfo';
import store from 'redux/Store';
import actionTypes from 'redux/actionTypes';

import { RESOURCE_ACTIONS, ResourceType } from './constants';

const genericActionToUserActions = (
    action: ActionResourceSchema,
    companyIds: number[],
    regionIds: number[],
    branchIds: number[]
) => {
    let ids: number[] = [];
    switch (action.resource as ResourceType) {
        case 'Company':
            ids = companyIds;
            break;
        case 'Region':
            ids = regionIds;
            break;
        case 'Community':
            ids = branchIds;
            break;
        default:
            Sentry.captureException(Error(`Unknown resource type: ${action.resource}`));
            break;
    }

    return ids.map((id) => ({
        ...action,
        resource: `${action.resource}:${id}`,
    }));
};

// Create Context
export const AbilityContext = createContext<MongoAbility<AbilityTuple, MongoQuery> | undefined>(undefined);

export const AbilityLoader = ({ children }: { children: React.ReactNode }) => {
    const { isSignedIn, sessionData } = useSelector((state: ReduxStore) => state.session);
    const { userId } = sessionData;
    const [ability, setAbility] = useState<MongoAbility<AbilityTuple, MongoQuery> | undefined>(undefined);
    const {
        data: branchesByRegionData,
        isError: branchesByRegionDataIsError,
        error: branchesByRegionDataError,
    } = useBranchesByRegionQuery(
        {
            companyId: sessionData.companyId,
            regionId: sessionData.regionId,
            branchId: sessionData.branchId,
        },
        !!isSignedIn
    );

    useEffect(() => {
        if (branchesByRegionDataIsError) {
            Sentry.captureException(branchesByRegionDataError);
            store.dispatch({ type: actionTypes.SESSION_PERMIT_LOADED, payload: null });
        }
    }, [branchesByRegionDataIsError]);

    useEffect(() => {
        const getAbility = async (user: number, companyIds: number[], regionIds: number[], branchIds: number[]) => {
            const permit = Permit({
                loggedInUser: user.toString(),
                backendUrl: `${process.env.REACT_APP_BASE_SERVER_URL}sessions/permission-check`,
            });

            const actions = concat(
                ...RESOURCE_ACTIONS.map((action) =>
                    genericActionToUserActions(action, companyIds, regionIds, branchIds)
                )
            );

            await permit.loadLocalStateBulk(actions);

            const caslConfig = permitState.getCaslJson();
            return caslConfig && caslConfig.length ? createMongoAbility(caslConfig) : undefined;
        };

        if (isSignedIn && branchesByRegionData) {
            const companyIds = branchesByRegionData.hasCompanyAccess ? [sessionData.companyId] : [];

            const regionIds = branchesByRegionData.regions
                .filter(({ hasAccess }) => hasAccess)
                .map(({ regionId }) => regionId);

            const branchIds = branchesByRegionData.branches
                .filter(({ hasAccess }) => hasAccess)
                .map(({ branchId }) => branchId);

            void getAbility(userId, companyIds, regionIds, branchIds).then((caslAbility) => {
                setAbility(caslAbility);
                store.dispatch({ type: actionTypes.SESSION_PERMIT_LOADED, payload: true });
            });
        }
    }, [isSignedIn, userId, branchesByRegionData]);

    return <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>;
};
