export const RESOURCE_ACTIONS = [
    { resource: 'Company', action: 'read-company' },
    { resource: 'Company', action: 'read-operations-dashboard' },
    { resource: 'Region', action: 'read-region' },
    { resource: 'Region', action: 'read-operations-dashboard' },
    { resource: 'Community', action: 'read-branch' },
    { resource: 'Community', action: 'read-all-users' },
    { resource: 'Community', action: 'read-reward' },
    { resource: 'Community', action: 'update-reward' },
    { resource: 'Community', action: 'read-resident' },
    { resource: 'Community', action: 'read-resident-deep-dive' },
    { resource: 'Community', action: 'create-resident-action' },
    { resource: 'Community', action: 'read-resident-action' },
    { resource: 'Community', action: 'update-resident-action' },
    { resource: 'Community', action: 'delete-resident-action' },
    { resource: 'Community', action: 'undertake-resident-action' },
    { resource: 'Community', action: 'read-all-resident-actions' },
    { resource: 'Community', action: 'read-operations-dashboard' },
    { resource: 'Community', action: 'read-performance-dashboard' },
    { resource: 'Community', action: 'receive-notification' },
    { resource: 'Community', action: 'require-geofencing' },
    { resource: 'Community', action: 'view-notification-center' },
] as const;

type ResourceActionTuples = (typeof RESOURCE_ACTIONS)[number];
type ResourceActionsByType = {
    [Resource in ResourceType]: Extract<
        ResourceActionTuples['action'],
        Extract<ResourceActionTuples, { resource: Resource }>['action']
    >[];
};

export type ResourceType = ResourceActionTuples['resource'];
export type ResourceActions<Resource extends ResourceType> = ResourceActionsByType[Resource][number];
