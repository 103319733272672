import { Box } from '@mui/material';
import { useGeolocation } from 'contexts/GeolocationContext';
import React, { useState } from 'react';

import GeolocationPrompt from 'assets/geolocation-prompt.svg';
import ActionPromptDialog from 'components/Shared/ActionPromptDialog';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    onCancel: () => void;
};

const ActivateGeolocationDialog = ({ isOpen, onClose, onCancel }: Props) => {
    const { updatePosition } = useGeolocation();
    const [isLoading, setIsLoading] = useState(false);

    const handleActivateGeolocation = async () => {
        setIsLoading(true);

        onClose();
        await updatePosition();

        setIsLoading(false);
    };

    return (
        <ActionPromptDialog
            title="Activate Geolocation"
            description="Geolocation enables essential features such as emergency response, location-based reminders, and safe zones. Activate now to unlock your access to the app."
            icon={
                <Box
                    component="img"
                    src={GeolocationPrompt}
                    alt="Activate Geolocation"
                    sx={{
                        mx: 'auto',
                    }}
                />
            }
            primaryActionLabel="Allow"
            onPrimaryAction={handleActivateGeolocation}
            isOpen={isOpen}
            onClose={onClose}
            onCancel={onCancel}
            isLoading={isLoading}
        />
    );
};

export default ActivateGeolocationDialog;
