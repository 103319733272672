import { CancelOutlined, CheckCircleOutlined } from '@mui/icons-material';
import { Box, CircularProgress, Typography, styled } from '@mui/material';
import pluralize from 'pluralize';
import React from 'react';

import { TaskLoadingState } from 'types/dailyTasks';

import { TASK_STATUS, TASK_STATUS_TO_TASK_STATUS_ID } from '@alliehealth/utils/dist/constants/task-records.constants';
import { pxToRem } from 'components/theme/typography';

const TrayContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        textAlign: 'center',
        justifyContent: 'center',
        backgroundColor: '#2C2D3B',
        color: 'primary.contrastText',
        padding: pxToRem(12),
    })
);

const SelectedTasksText = styled(Typography)(({ theme }) =>
    theme.unstable_sx({
        textAlign: 'left',
        flexGrow: 1,
        margin: 'auto',
    })
);

const ButtonsRow = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    })
);

const ButtonContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        width: pxToRem(54),
        height: pxToRem(54),
        position: 'relative',
    })
);

const ButtonLoading = styled(CircularProgress)(({ theme }) =>
    theme.unstable_sx({
        color: 'primary.contrastText',
        position: 'absolute',
        margin: pxToRem(4),
        pointerEvents: 'none',
    })
);

type Props = {
    selectedTaskIds: number[];
    loadingBulkState: TaskLoadingState;
    onToggleRejectDialog: (taskId: number) => void;
    confirmSelectedTasks: (taskStatusId: number) => void;
};

export const BulkCompleteTray = (props: Props) => {
    const { selectedTaskIds, loadingBulkState, onToggleRejectDialog, confirmSelectedTasks } = props;

    const buttonStyles = {
        height: pxToRem(54),
        width: pxToRem(54),
        opacity: loadingBulkState ? 0.2 : 1,
    };

    return (
        <TrayContainer>
            <SelectedTasksText>
                {selectedTaskIds.length} {pluralize('task', selectedTaskIds.length)} selected
            </SelectedTasksText>
            <ButtonsRow>
                <ButtonContainer>
                    {loadingBulkState === 'confirm' && <ButtonLoading size={46} thickness={4.8} />}
                    <CheckCircleOutlined
                        onClick={() =>
                            !loadingBulkState &&
                            confirmSelectedTasks(TASK_STATUS_TO_TASK_STATUS_ID[TASK_STATUS.COMPLETED])
                        }
                        sx={buttonStyles}
                    />
                </ButtonContainer>
                <ButtonContainer>
                    {loadingBulkState === 'reject' && <ButtonLoading size={46} thickness={4.8} />}
                    <CancelOutlined onClick={() => !loadingBulkState && onToggleRejectDialog(-1)} sx={buttonStyles} />
                </ButtonContainer>
            </ButtonsRow>
        </TrayContainer>
    );
};
