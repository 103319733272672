import { Box } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';

import { CustomAvatar } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

const PHOTO_WIDTH = 64;

type Props = {
    photo: string;
    firstName: string;
    lastName: string;
    residentId: number;
};

export const ResidentPhoto = (props: Props) => {
    const { firstName, lastName, photo, residentId } = props;

    const navigate = useNavigate();

    const goToResidentDetails = () => navigate(`/residents/${residentId}`);

    return (
        <Box
            onClick={goToResidentDetails}
            sx={{
                marginRight: pxToRem(12),
                marginLeft: pxToRem(12),
                width: pxToRem(PHOTO_WIDTH),
            }}
        >
            <CustomAvatar firstName={firstName} lastName={lastName} photo={photo} size={PHOTO_WIDTH} />
        </Box>
    );
};
