import { useAtom } from 'jotai';
import { uniqBy } from 'lodash';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { BranchesByRegionResponseBranch, BranchesByRegionResponseRegion, CareTypeResponse } from 'types/companyInfo';
import { SelectStructure } from 'types/inputs';
import { ReduxStore } from 'types/redux';

import { OperationsDashboardHeader } from './components/OperationsDashboardHeader';
import { OperationsDashboardMain } from './components/OperationsDashboardMain';
import { useBranchesByRegionQuery } from 'api/queries/companyInfo';
import Loading from 'components/Shared/Loading';

import { branchAtom, careTypeAtom, mobileTabSelectedAtom, operationsPeriodAtom, regionAtom } from './atoms';

export const OperationsDashboard = () => {
    const {
        sessionData: { companyId },
    } = useSelector((state: ReduxStore) => state.session);

    const [region, setRegion] = useAtom(regionAtom);
    const [branch, setBranch] = useAtom(branchAtom);
    const [careType, setCareType] = useAtom(careTypeAtom);
    const [operationsPeriod, setOperationsPeriod] = useAtom(operationsPeriodAtom);
    const [mobileTabSelected, setMobileTabSelected] = useAtom(mobileTabSelectedAtom);

    const {
        data: branchesByRegionData,
        isLoading: branchesByRegionDataIsLoading,
        isError: branchesByRegionDataIsError,
    } = useBranchesByRegionQuery({
        companyId,
        regionId: Number(region),
        branchId: Number(branch),
    });

    const accessibleRegions: BranchesByRegionResponseRegion[] | [] =
        branchesByRegionData?.regions.filter(({ hasAccess }) => hasAccess) || [];

    const accessibleBranches: BranchesByRegionResponseBranch[] =
        branchesByRegionData?.branches.filter(({ hasAccess }) => hasAccess) || [];

    useEffect(() => {
        if (!branchesByRegionData || branchesByRegionDataIsLoading) {
            return;
        }
        if (branchesByRegionData?.hasCompanyAccess && accessibleRegions.length > 0) {
            setBranch('all');
            setRegion('all');
        } else if (accessibleRegions.length > 0) {
            setBranch('all');
            setRegion(accessibleRegions[0].regionId.toString());
        } else {
            setBranch(accessibleBranches[0].branchId.toString());
            if (accessibleBranches[0].regionId) {
                setRegion(accessibleBranches[0].regionId.toString());
            }
        }
    }, [branchesByRegionData]);

    if (branchesByRegionDataIsLoading) {
        return <Loading />;
    }

    if (branchesByRegionDataIsError || !branchesByRegionData) {
        return null;
    }

    const { hasCompanyAccess } = branchesByRegionData;

    let regionOptions: SelectStructure[] =
        hasCompanyAccess && accessibleRegions.length > 0 ? [{ label: 'All', value: 'all' }] : [];
    if (accessibleRegions.length > 0) {
        regionOptions = [
            ...regionOptions,
            ...accessibleRegions.map((item: BranchesByRegionResponseRegion) => ({
                label: item.regionShortName,
                value: item.regionId.toString(),
            })),
        ];
    }

    let branchesOptions: SelectStructure[] = accessibleBranches.length > 0 ? [{ label: 'All', value: 'all' }] : [];
    if (region !== 'all') {
        branchesOptions = [
            ...branchesOptions,
            ...accessibleBranches
                .filter((item) => item.regionId === Number(region))
                .map((item) => ({
                    label: item.branchShortName,
                    value: item.branchId.toString(),
                })),
        ];
    } else {
        branchesOptions = [
            ...branchesOptions,
            ...accessibleBranches.map((item) => ({
                label: item.branchShortName,
                value: item.branchId.toString(),
            })),
        ];
    }

    const allCompanyAvailableCareTypes = uniqBy(
        [...Object.values(branchesByRegionData.availableCareTypesByBranchId)].flat(),
        'code'
    );

    let careTypeOptions: SelectStructure[] = [];
    if (branch === 'all') {
        careTypeOptions = allCompanyAvailableCareTypes.length > 1 ? [{ label: 'All', value: 'all' }] : [];
        careTypeOptions = [
            ...careTypeOptions,
            ...Array.from(allCompanyAvailableCareTypes).map((item: CareTypeResponse) => ({
                label: item.name,
                value: item.code,
            })),
        ];
    } else {
        careTypeOptions =
            branchesByRegionData.availableCareTypesByBranchId[branch].length > 1
                ? [{ label: 'All', value: 'all' }]
                : [];
        careTypeOptions = [
            ...careTypeOptions,
            ...branchesByRegionData.availableCareTypesByBranchId[branch].map((item: CareTypeResponse) => ({
                label: item.name,
                value: item.code,
            })),
        ];
    }

    const handleTabChange = (newValue: 0 | 1) => {
        setMobileTabSelected(newValue);
    };

    return (
        <>
            <OperationsDashboardHeader
                region={region}
                branch={branch}
                careType={careType}
                operationsPeriod={operationsPeriod}
                onRegionChange={setRegion}
                onBranchChange={setBranch}
                onCareTypeChange={setCareType}
                onPeriodChange={setOperationsPeriod}
                mobileTabSelected={mobileTabSelected}
                onMobileTabChange={handleTabChange}
                regionOptions={regionOptions}
                branchesOptions={branchesOptions}
                careTypeOptions={careTypeOptions}
                hasCompanyAccess={hasCompanyAccess}
            />
            <OperationsDashboardMain
                mobileTabSelected={mobileTabSelected}
                onMobileTabChange={handleTabChange}
                region={region}
                branch={branch}
                careType={careType}
                operationsPeriod={operationsPeriod}
                hasCompanyAccess={hasCompanyAccess}
                hasRegions={accessibleRegions.length > 0}
                hasBranches={accessibleBranches.length > 1} // There'll always be at least one branch
            />
        </>
    );
};

export default OperationsDashboard;
