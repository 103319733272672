import React from 'react';
import { connect } from 'react-redux';

import { ReduxStore } from 'types/redux';

import PageStructure from 'pages/PageStructure';

import BadgesSection from './home/BadgesSection';
import LastShiftPointsSummary from './home/LastShiftPointsSummary';
import RedeemSection from './home/RedeemSection';
import ThanksSection from './home/ThanksSection';

type Props = {
    userId: number;
    branchId: number;
};

const MyRewards = ({ userId, branchId }: Props) => (
    <PageStructure>
        <RedeemSection userId={userId} branchId={branchId} />
        <LastShiftPointsSummary userId={userId} branchId={branchId} />
        <BadgesSection userId={userId} branchId={branchId} />
        <ThanksSection userId={userId} branchId={branchId} />
    </PageStructure>
);

const mapStateToProps = ({ session }: ReduxStore) => {
    const {
        sessionData: { userId, branchId },
    } = session;

    return {
        userId,
        branchId,
    };
};

export default connect(mapStateToProps)(MyRewards);
