import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import React from 'react';

import { OperationsPeriod } from 'types/operationsDashboard';

import { pxToRem } from 'components/theme/typography';

type Props = {
    operationsPeriod: OperationsPeriod;
    onPeriodChange: React.Dispatch<React.SetStateAction<OperationsPeriod>>;
    showDay?: boolean;
};

export const PeriodSelector = ({ operationsPeriod, onPeriodChange, showDay }: Props) => {
    const handleButtonChange = (event: React.MouseEvent<HTMLElement>, newPeriod: OperationsPeriod) => {
        if (newPeriod === 'day' || newPeriod === 'week' || newPeriod === 'month') {
            onPeriodChange(newPeriod);
        }
    };

    const OPERATIONS_PERIODS: OperationsPeriod[] = showDay ? ['day', 'week', 'month'] : ['week', 'month'];

    return (
        <ToggleButtonGroup
            value={operationsPeriod}
            exclusive
            onChange={handleButtonChange}
            sx={{
                display: 'grid',
                gridTemplateColumns: `repeat(${OPERATIONS_PERIODS.length}, 1fr)`,
                gap: { xs: 1, lg: 2 },
                m: 0,
                ml: 'auto',
                borderRadius: pxToRem(8),
                backgroundColor: '#ffffff14',
                border: { xs: '1px solid #ffffff1f', lg: 'none' },
                p: { xs: pxToRem(4), lg: 0 },
            }}
        >
            {OPERATIONS_PERIODS.map((period) => (
                <ToggleButton
                    key={period}
                    value={period}
                    disableRipple
                    sx={{
                        px: {
                            xs: pxToRem(8),
                            lg: 0,
                        },
                        py: {
                            xs: pxToRem(2),
                            lg: 0,
                        },
                        color: { xs: '#C7C8CB', lg: '#2C2D3B' },
                        borderRadius: {
                            xs: `${pxToRem(4)} !important`,
                        },
                        border: 'none',
                        textTransform: 'capitalize',
                        fontSize: { xs: pxToRem(14), lg: pxToRem(16) },
                        fontWeight: {
                            xs: 400,
                            lg: 500,
                        },
                        lineHeight: { xs: 1.45, lg: 1.5 },
                        '&.Mui-selected': {
                            bgcolor: {
                                xs: 'common.white',
                                lg: 'transparent',
                            },
                            fontWeight: {
                                xs: 500,
                                lg: 700,
                            },
                            color: { xs: '#2C2D3B', lg: 'primary.dark' },
                            '&:focus': {
                                bgcolor: {
                                    xs: 'common.white',
                                    lg: 'transparent',
                                },
                                border: 'none',
                            },
                            '::after': {
                                content: '""',
                                position: 'absolute',
                                inset: 0,
                                borderRadius: 0,
                                width: '100%',
                                bgcolor: 'transparent',
                                borderBottom: { lg: '2px solid #006B75' },
                            },
                        },
                        '&:hover': {
                            bgcolor: {
                                lg: 'transparent',
                            },
                            '&.Mui-selected': {
                                bgcolor: {
                                    lg: 'transparent',
                                },
                            },
                        },
                    }}
                >
                    {period}
                </ToggleButton>
            ))}
        </ToggleButtonGroup>
    );
};
