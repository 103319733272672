import { AnyAction } from 'redux';

import actionTypes from '../actionTypes';

const initialState = {
    selectedStartDate: undefined,
    selectedStartDatePeriod: undefined,
};

const opsDashboard = (state = initialState, action: AnyAction) => {
    switch (action.type) {
        case actionTypes.SELECTED_START_DATE_SET:
            return {
                ...state,
                selectedStartDate: action.payload.date as string | undefined,
                selectedStartDatePeriod: (action.payload.period as string | undefined) ?? state.selectedStartDatePeriod,
            };
        default:
            return state;
    }
};

export default opsDashboard;
