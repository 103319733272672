import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { pxToRem } from 'components/theme/typography';

type Props = {
    question: string;
    linkLabel: string;
} & (
    | {
          linkUrl: string;
          isLink?: true;
          onClick?: never;
      }
    | {
          linkUrl?: never;
          isLink?: false;
          onClick: () => void;
      }
);

const AuthCtaLink = ({ question, linkLabel, linkUrl, isLink, onClick }: Props) => (
    <Typography
        sx={{
            width: '100%',
            textAlign: 'center',
            fontSize: {
                xs: pxToRem(14),
            },
            lineHeight: {
                xs: 1.45,
            },
            color: '#2C2D3B',
        }}
    >
        {question}{' '}
        {isLink ? (
            <Link
                component={RouterLink}
                to={`/${linkUrl}`}
                sx={{
                    color: 'primary.dark',
                    fontWeight: 600,
                }}
            >
                {linkLabel}
            </Link>
        ) : (
            <Box
                component={Link}
                href="#"
                role="button"
                sx={{
                    color: 'primary.dark',
                    fontWeight: 600,
                    textDecoration: 'underline',
                }}
                onClick={onClick}
            >
                {linkLabel}
            </Box>
        )}
    </Typography>
);

export default AuthCtaLink;
