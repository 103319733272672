import { Capacitor } from '@capacitor/core';
import { useGeolocation } from 'contexts/GeolocationContext';
import { usePermissions } from 'permissions/utils';

import { useBranchQuery } from 'api/queries/branch';
import { usePermissionsQuery } from 'api/queries/permissions';

type GeofencingState = 'not-applicable' | 'inside' | 'outside' | 'prompt' | 'unknown';

export const useGeofencing = (branchId?: number): GeofencingState => {
    const { isSuccess: isPermitLoaded } = usePermissionsQuery();
    const hasPermission = usePermissions();

    const { data: branchData } = useBranchQuery(branchId);
    const { permissionStatus, position, isPositionAccurate, isUserInsideWorkingArea } = useGeolocation();

    if (!Capacitor.isNativePlatform()) return 'not-applicable';

    if (!isPermitLoaded) return 'unknown';

    // Although we call it a 'permission', geofencing is more like the opposite
    if (!hasPermission('Community', 'require-geofencing')) return 'not-applicable';

    if (!branchData) return 'unknown';

    const { locationLatitude, locationLongitude, geofencingEnabled } = branchData;

    if (!geofencingEnabled) return 'not-applicable';

    if (
        permissionStatus?.location.includes('prompt') ||
        permissionStatus?.location === 'denied' ||
        isPositionAccurate === false
    ) {
        return 'prompt';
    }

    if (!position) return 'unknown';

    if (!isUserInsideWorkingArea({ branchLat: locationLatitude!, branchLon: locationLongitude!, maxDistance: 0.5 }))
        return 'outside';

    return 'inside';
};
