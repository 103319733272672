import { ActionType } from 'types/redux';

const createActionTypes = (types: string[]): ActionType =>
    types.reduce((actionTypes, key) => ({ ...actionTypes, [key]: key }), {});

export default createActionTypes([
    // Messages
    'MESSAGE_SHOW',
    'MESSAGE_HIDE',
    // Session
    'SESSION_SIGN_IN',
    'SESSION_SIGN_OUT',
    'SESSION_VALIDATE_SESSION',
    'SESSION_UPDATE_PRIOR_PARENT_PAGE',
    'SESSION_UPDATE_TIMEZONE',
    'SESSION_PERMIT_LOADED',
    'SESSION_POSTHOG_LOADED',
    // Users
    'USER_READ',
    'USER_READ_LIST',
    'USER_LOADING',
    // Zones
    'ZONE_READ_LIST',
    'ZONE_LOADING',
    // Tasks Statuses
    'TASK_STATUS_READ_LIST',
    'TASK_STATUS_LOADING',
    // Daily Tasks
    'DAILY_TASKS_LOADING',
    // Residents
    'RESIDENT_TOGGLE_DISPLAY_PARTY',
    // Shift Notes Categories
    'SHIFT_NOTES_CATEGORY_READ_LIST',
    'SHIFT_NOTES_CATEGORY_LOADING',
    // Filters
    'FILTER_CAREGIVER_ZONE_UPDATE',
    'SORT_BY_UPDATE',
    'SORT_ORDER_UPDATE',
    // Ops Dashboard
    'SELECTED_START_DATE_SET',
    // UI
    'MOBILE_DRAWER_TOGGLE',
]);
