import { api } from 'api';

import { CompanyCarePlanResponse } from 'types/companyCarePlans';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { useToken } from 'lib/common';

export const useCompanyCarePlansListQuery = (branchId: number) => {
    const token = useToken();

    return useErrorHandledQuery<CompanyCarePlanResponse[]>(
        ['company-care-plans-list'],
        async (): Promise<CompanyCarePlanResponse[]> => {
            const { data } = await api.get('/company-care-plans', {
                headers: { authorization: token },
                params: { branch_id: branchId },
            });
            return data.response;
        },
        { enabled: !!branchId, staleTime: 1800000 }
    );
};
