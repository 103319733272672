import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import * as Sentry from '@sentry/react';
import { api } from 'api';
import { PostHog } from 'posthog-js/react';

import { AppDispatch } from 'types/redux';
import { SessionData, StoredSession } from 'types/session';

import { HOME_FILTERS, PROFILE, PUSH_NOTIFICATION_TOKEN_LAST_UPDATED } from 'constants/localStorage';
import actionTypes from 'redux/actionTypes';
import 'redux/reducers/session';

export const validateSession = (posthog: PostHog) => (dispatch: AppDispatch) => {
    try {
        const storedProfile = (JSON.parse(localStorage.getItem(PROFILE) as string) ?? {}) as StoredSession;
        const { isSignedIn = false, sessionData = {} as SessionData } = storedProfile;
        const { userId, username } = sessionData;

        if (userId && username) {
            Sentry.setUser({ id: userId, username });

            posthog.identify(userId.toString(), { username });
            posthog.setPersonPropertiesForFlags({ username });
        }

        dispatch({
            type: actionTypes.SESSION_VALIDATE_SESSION,
            payload: {
                isSignedIn,
                sessionData,
            },
        });
    } catch (error) {
        // Expected if the user is not signed in or the session is no longer valid
    }
};

/**
 * Signs Out a user.
 */
export const signOut = (redirect?: string) => async (dispatch, getState) => {
    try {
        // Get the token.
        const { token } = getState().session.sessionData;
        const identifier = Capacitor.isNativePlatform() ? (await Device.getId()).identifier : undefined;

        // Sign-out the User.
        await api.delete('sessions', {
            headers: {
                authorization: token,
                'device-id': identifier,
            },
        });
    } catch (error) {
        Sentry.captureException(error);
    } finally {
        // Destroy the session locally.
        const profile: StoredSession = {
            isSignedIn: false,
            sessionData: {} as SessionData,
        };

        localStorage.removeItem(PROFILE);
        localStorage.removeItem(HOME_FILTERS);
        localStorage.removeItem(PUSH_NOTIFICATION_TOKEN_LAST_UPDATED);

        dispatch({
            type: actionTypes.SESSION_SIGN_OUT,
            payload: profile,
        });

        Sentry.setUser(null);

        let url = '/login';
        if (redirect) url += `?redirect=${redirect}`;
        window.location.href = url;
    }
};

export const updateTimezone = (timezone: string) => ({
    type: actionTypes.SESSION_UPDATE_TIMEZONE,
    payload: timezone,
});
