import React from 'react';
import { Navigate, isRouteErrorResponse, useNavigate, useRouteError } from 'react-router';

import { CenteredText, CustomButton, CustomButtonContainer, FullScreenContainer } from './shared';

const NavigateError = () => {
    const navigate = useNavigate();
    const error = useRouteError();

    if (isRouteErrorResponse(error) && error.status === 404) return <Navigate to="/" replace />;

    return (
        <FullScreenContainer>
            <CenteredText>
                An error has occurred.
                <br />
                Please try again later.
            </CenteredText>
            <CustomButtonContainer>
                <CustomButton
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => navigate('/', { replace: true })}
                >
                    Go Back
                </CustomButton>
            </CustomButtonContainer>
        </FullScreenContainer>
    );
};

export default NavigateError;
