import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { usePostHog } from 'posthog-js/react';
import { useEffect } from 'react';

import { APP_VERSION } from 'constants/app';

type AppProperties = {
    appVersion: string;
    nativeVersion?: string;
};

export const isPostHogEnabled = process.env.REACT_APP_ENABLE_POSTHOG === 'true';

export const PostHogHandler = () => {
    const posthog = usePostHog();

    useEffect(() => {
        const run = async () => {
            const properties: AppProperties = {
                appVersion: APP_VERSION,
            };

            if (Capacitor.isNativePlatform()) {
                const { version } = await App.getInfo();
                properties.nativeVersion = version;
            }

            posthog?.register(properties);
        };

        run();
    }, []);

    return null;
};
