import { formatInTimeZone } from 'date-fns-tz';
import 'polyfills';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { IntercomProvider } from 'react-use-intercom';
import Root from 'root';
import timemachine from 'timemachine';

import { isPostHogEnabled } from 'helpers/PostHogHandler';
import Store from 'redux/Store';

import reportWebVitals from './reportWebVitals';

// importing timemachine automatically changes the datetime.
// This resets the time to the real time so we can act like it's still the same time of the day
timemachine.reset();
const realTime = formatInTimeZone(new Date(), 'America/Chicago', 'HH:mm:ss');

timemachine.config({
    dateString: `June 15, 2024 ${realTime}`,
    tick: true,
});

if (isPostHogEnabled) {
    posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
        api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
        ui_host: 'https://us.posthog.com', // required with reverse proxy
        opt_in_site_apps: true,
        feature_flag_request_timeout_ms: 0,
        loaded: () => {
            Store.dispatch({
                type: 'SESSION_POSTHOG_LOADED',
                payload: true,
            });
        },
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={Store}>
        <PostHogProvider client={posthog}>
            <IntercomProvider appId="ydfuumfb" autoBoot>
                <Root />
            </IntercomProvider>
        </PostHogProvider>
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
