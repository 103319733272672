import { usePermissions } from 'permissions/utils';
import React from 'react';
import { Navigate } from 'react-router';

const NavigateHome = () => {
    const hasPermission = usePermissions();
    const hasOpsDashboardAccess = hasPermission('Community', 'read-operations-dashboard');
    return <Navigate to={hasOpsDashboardAccess ? '/operations' : '/home'} replace />;
};

export default NavigateHome;
