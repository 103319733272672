import { Box } from '@mui/material';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

const PageStructure = ({ children }: { children: React.ReactNode }) => (
    <Box sx={{ p: { xs: pxToRem(8), lg: pxToRem(16) } }}>{children}</Box>
);

export default PageStructure;
