import momentTZ from 'moment-timezone';
import { PostHog, usePostHog } from 'posthog-js/react';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { connect } from 'react-redux';
import { RouterProvider } from 'react-router-dom';
import { router } from 'router';

import { AppDispatch, ReduxStore } from 'types/redux';

import ThemeConfig from 'components/theme';
import GlobalStyles from 'components/theme/globalStyles';
import { APP_NAME } from 'constants/app';
import { updateTimezone, validateSession } from 'redux/actions/session';

type Props = {
    timezone: string;
    dispatchUpdateTimezone: (timezone: string) => void;
    dispatchValidateSession: (posthog: PostHog) => void;
};

const Root = (props: Props) => {
    const { timezone, dispatchUpdateTimezone, dispatchValidateSession } = props;
    const posthog = usePostHog();

    useEffect(() => {
        const userTimezone = momentTZ.tz.guess();

        if (!timezone || timezone !== userTimezone) {
            dispatchUpdateTimezone(userTimezone);
        }

        dispatchValidateSession(posthog);
    }, []);

    return (
        <>
            <Helmet defaultTitle={APP_NAME}>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, user-scalable=no, viewport-fit=cover"
                />
                <meta name="robots" content="no-index" />
                <link rel="shortcut icon" href="/favicon.icon" />
                <link rel="preconnect" href="https://fonts.googleapis.com" />
                <link rel="preconnect" href="https://fonts.gstatic.com" />
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Raleway:wght@400;700&family=Inter:wght@100..900&display=swap"
                />
            </Helmet>

            {/* Everything must be wrapped in the RouterProvider,
            except theming, because we want pretty error pages */}
            <ThemeConfig>
                <GlobalStyles />
                <RouterProvider router={router} />
            </ThemeConfig>
        </>
    );
};

const mapStateToProps = ({ session }: ReduxStore) => {
    const { timezone } = session;
    return { timezone };
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
    dispatchUpdateTimezone: (timezone: string) => dispatch(updateTimezone(timezone)),
    dispatchValidateSession: (posthog: PostHog) => dispatch(validateSession(posthog)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Root);
