import { Box } from '@mui/material';
import React, { useMemo } from 'react';
import { IconContext } from 'react-icons';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { FaMinusCircle } from 'react-icons/fa';

import { pxToRem } from 'components/theme/typography';

const SelectorIcon = ({
    isSelected,
    areAllTasksSelected,
    isResidentSelector,
}: {
    isSelected: boolean;
    areAllTasksSelected?: boolean;
    isResidentSelector?: boolean;
}) => {
    if (!isSelected) {
        return (
            <Box
                sx={{
                    width: pxToRem(24),
                    height: pxToRem(24),
                    borderRadius: pxToRem(24),
                    backgroundColor: 'selector.deselected',
                }}
            />
        );
    }

    const styles = useMemo(
        () => ({
            color: '#30878f',
            size: pxToRem(24),
            attr: { display: 'flex' },
        }),
        []
    );

    const showCheckIcon = !isResidentSelector || (isResidentSelector && areAllTasksSelected);

    return (
        <IconContext.Provider value={styles}>
            {showCheckIcon ? <BsFillCheckCircleFill /> : <FaMinusCircle />}
        </IconContext.Provider>
    );
};

type Props = {
    isSelected: boolean;
    onSelect: () => void;
    areAllTasksSelected?: boolean;
    isResidentSelector?: boolean;
    id?: string;
};

export const Selector = (props: Props) => {
    const { isSelected, onSelect, areAllTasksSelected, isResidentSelector, id } = props;

    return (
        <Box
            onClick={onSelect}
            sx={{
                width: pxToRem(24),
                height: pxToRem(24),
                display: 'flex',
                alignItems: 'center',
                textAlign: 'center',
                marginTop: isResidentSelector ? 0 : pxToRem(8),
            }}
            id={id || ''}
        >
            <Box>
                <SelectorIcon
                    isSelected={isSelected}
                    areAllTasksSelected={areAllTasksSelected}
                    isResidentSelector={isResidentSelector}
                />
            </Box>
        </Box>
    );
};
