import { Button } from '@mui/material';
import RadioGroup from '@mui/material/RadioGroup';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { TaskCompletionCount } from 'types/dailyTasks';
import { ReduxStore } from 'types/redux';

import { useCreateDailyTask } from 'api/queries/tasks/dailyTasks';
import { CustomMobileDialog, CustomRadioGroupOptions } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';

type Props = {
    isOpen: boolean;
    onClose: () => void;
    tasksCompletionCountData: TaskCompletionCount[];
    residentId: number;
    date: string;
    shift: number;
};

const TasksCompletionCountDialog = ({ isOpen, onClose, tasksCompletionCountData, residentId, date, shift }: Props) => {
    const [selectedValue, setSelectedValue] = useState('');
    const [currentStep, setCurrentStep] = useState(0);

    const currentData = tasksCompletionCountData[currentStep];
    const carePlanName = currentData ? currentData.carePlanName : '';

    const { branchId } = useSelector((state: ReduxStore) => state.session.sessionData);

    const createTaskMutation = useCreateDailyTask();

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedValue(event.target.value);
    };

    const handleClose = () => {
        onClose();
        setSelectedValue('');
        setCurrentStep(0);
    };

    const advanceStep = () => {
        if (tasksCompletionCountData && currentStep < tasksCompletionCountData.length - 1) {
            setCurrentStep((prev) => prev + 1);
            setSelectedValue('');
        } else {
            handleClose();
            setSelectedValue('');
        }
    };

    const handleSubmit = async () => {
        // Check difference between number of tasks selected by the caregiver and number of tasks already completed
        const numberOfTasksToCreate = Number(selectedValue) - currentData.tasksCompleted;
        if (numberOfTasksToCreate > 0) {
            const params = {
                branchId: branchId!,
                residentId,
                companyCarePlanId: currentData.carePlanId,
                date,
                shiftId: shift,
            };

            await Promise.all(
                Array.from({ length: numberOfTasksToCreate }).map(() => createTaskMutation.mutateAsync(params))
            );
        }

        advanceStep();
    };

    const countOptions = [
        { id: 1, name: '1 time' },
        { id: 2, name: '2 times' },
        { id: 3, name: '3 times' },
        {
            id: 4,
            name: '4 or more times',
        },
    ];

    return (
        <CustomMobileDialog
            closeable
            open={isOpen}
            title={`How many times did you complete ${carePlanName} this shift?`}
            width="100%"
            onClose={handleClose}
            content={
                <>
                    <RadioGroup
                        name="choose-task-completion-count"
                        defaultValue=""
                        onChange={handleRadioChange}
                        value={selectedValue}
                        sx={{
                            display: 'grid',
                            gap: pxToRem(12),
                            m: 0,
                            mb: pxToRem(16),
                        }}
                    >
                        {countOptions.map(({ id, name }) => (
                            <CustomRadioGroupOptions
                                key={id}
                                value={id.toString()}
                                label={name}
                                selectedValue={selectedValue}
                            />
                        ))}
                    </RadioGroup>

                    <Button
                        color="primary"
                        onClick={handleSubmit}
                        disabled={!selectedValue}
                        sx={{
                            width: '100%',
                            p: pxToRem(12),
                            mt: pxToRem(8),
                            fontSize: pxToRem(14),
                            '&:disabled': {
                                bgcolor: '#C7C8CB',
                            },
                        }}
                    >
                        Submit
                    </Button>
                </>
            }
        />
    );
};

export default TasksCompletionCountDialog;
