import { Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { IoIosAlert as AlertIcon } from 'react-icons/io';
import { useNavigate } from 'react-router';

import { useRequestDeleteAccountMutation } from 'api/queries/deleteAccount/deleteAccount';
import { CustomDialog } from 'components/Custom';
import { pxToRem } from 'components/theme/typography';
import PageStructure from 'pages/PageStructure';

const InfoText = ({ text }: { text: string }) => (
    <Box sx={{ display: 'flex', gap: pxToRem(12) }}>
        <Box
            sx={{
                width: '0.6rem',
                height: '0.6rem',
                backgroundColor: '#478E94',
                borderRadius: '100%',
                marginTop: '0.45rem',
            }}
        />
        <Typography variant="body2" sx={{ flex: 1, fontWeight: '300', fontSize: 14 }}>
            {text}
        </Typography>
    </Box>
);

const DeleteAccount = () => {
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);

    const navigate = useNavigate();

    const { mutate: requestDeleteAccountMutation } = useRequestDeleteAccountMutation();

    const requestDeleteAccount = () => {
        requestDeleteAccountMutation();
    };

    const goToDeleteAccountVerifyPage = () => {
        navigate('/profile/verify-delete-account');
    };

    const closeDialogs = () => {
        setConfirmDialogOpen(false);
    };

    const Container = styled(Box)(({ theme }) => ({
        maxWidth: 560,
        padding: pxToRem(20),
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        gap: pxToRem(16),
        backgroundColor: '#F9FBFB',
        borderRadius: 8,
    }));

    const ProceedButton = styled(Button)(({ theme }) => ({
        borderRadius: pxToRem(8),
        fontWeight: 700,
        fontSize: pxToRem(16),
        backgroundColor: '#eee !important',
        color: '#006B75 !important',
        padding: pxToRem(16),
    }));

    return (
        <>
            {/* Confirmation Dialog */}
            <CustomDialog
                closeable
                open={confirmDialogOpen}
                width="100%"
                maxHeight={`calc(100% - ${pxToRem(200)})`}
                onClose={closeDialogs}
                title="Delete your Account?"
                subtitle={
                    <Typography variant="body1" sx={{ marginX: pxToRem(16) }}>
                        This action cannot be undone.
                    </Typography>
                }
                content={null}
                actions={
                    <>
                        <Button variant="outlined" color="secondary" onClick={closeDialogs}>
                            No
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                requestDeleteAccount();
                                setConfirmDialogOpen(false);
                                goToDeleteAccountVerifyPage();
                            }}
                        >
                            Yes
                        </Button>
                    </>
                }
            />
            <PageStructure>
                <Container>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h2" sx={{ fontWeight: '500' }}>
                            Delete your Account
                        </Typography>
                        <AlertIcon size={36} color="#e11010" />
                    </Box>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: 16 }}>
                        Hi! Are you sure you want to do this?
                    </Typography>
                    <InfoText
                        text='If you are trying to sign out of your account without deleting it, you can click the
                            "Sign Out" button on the left side drawer.'
                    />
                    <InfoText
                        text="If you proceed to delete your account, this cannot be undone. It cannot be recovered after you
                        perform this action."
                    />
                    <InfoText text="Any remaining rewards that have not been claimed will be lost." />
                    <InfoText text="If you need to use AllieHealth again, you will have to create another account." />
                    <ProceedButton
                        onClick={() => {
                            setConfirmDialogOpen(true);
                        }}
                    >
                        Proceed to delete your account
                    </ProceedButton>
                </Container>
            </PageStructure>
        </>
    );
};

export default DeleteAccount;
