import { format, isValid } from 'date-fns';

/* eslint-disable no-shadow */
export enum ExportDataTypes {
    SHIFT_NOTES = 'SHIFT_NOTES',
    TASK_RECORDS = 'TASK_RECORDS',
    REGULATOR_FLOW_SHEET = 'REGULATOR_FLOW_SHEET',
}

export const safeFormatDate = (date: Date): string | null => {
    if (isValid(date)) {
        return format(date, 'yyyy-MM-dd');
    }

    return null;
};

// From https://stackoverflow.com/a/63965930/5699461
export const downloadBlob = (blob: Blob, filename: string) => {
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
};

const cleanBranchName = (branchName: string) => branchName.replace(/ /g, '_');

const getShiftNotesFilename = (branchName: string, startDate: Date, endDate: Date, resident?: string) =>
    `AllieHealth_${cleanBranchName(branchName)}_Shift_Notes${resident ? `_for_${resident}` : ''}_from_${safeFormatDate(startDate)}_to_${safeFormatDate(endDate)}.csv`;

const getTaskRecordsFilename = (branchName: string, startDate: Date, endDate: Date, resident?: string) =>
    `AllieHealth_${cleanBranchName(branchName)}_Task_Records${resident ? `_for_${resident}` : ''}_from_${safeFormatDate(startDate)}_to_${safeFormatDate(endDate)}.csv`;

const getRegulatorFlowSheetFilename = (branchName: string, startDate: Date, endDate: Date, resident?: string) =>
    `AllieHealth_${cleanBranchName(branchName)}_Regulator_Flow_Sheet${resident ? `_for_${resident}` : ''}_from_${safeFormatDate(startDate)}_to_${safeFormatDate(endDate)}.pdf`;

export const mapFileNameGenerator: Record<
    ExportDataTypes,
    (branchName: string, startDate: Date, endDate: Date, resident?: string) => string
> = {
    [ExportDataTypes.SHIFT_NOTES]: getShiftNotesFilename,
    [ExportDataTypes.TASK_RECORDS]: getTaskRecordsFilename,
    [ExportDataTypes.REGULATOR_FLOW_SHEET]: getRegulatorFlowSheetFilename,
};
