import React from 'react';

import { usePermissionsQuery } from 'api/queries/permissions';
import { useAppDispatch } from 'constants/redux';
import { signOut } from 'redux/actions/session';

import NavigationLoading from './NavigationLoading';
import { CenteredText, CustomButton, CustomButtonContainer, FullScreenContainer } from './shared';

const PermissionsLoading = () => {
    const { isError, refetch } = usePermissionsQuery();
    const dispatch = useAppDispatch();

    if (isError) {
        return (
            <FullScreenContainer>
                <CenteredText>
                    Could not load your permissions.
                    <br />
                    Please try again later.
                </CenteredText>
                <CustomButtonContainer>
                    <CustomButton variant="outlined" color="primary" fullWidth onClick={() => void dispatch(signOut())}>
                        Sign Out
                    </CustomButton>
                    <CustomButton variant="contained" color="primary" fullWidth onClick={() => void refetch()}>
                        Try Again
                    </CustomButton>
                </CustomButtonContainer>
            </FullScreenContainer>
        );
    }

    return <NavigationLoading text="Loading your permissions..." />;
};

export default PermissionsLoading;
