import { Box, Divider } from '@mui/material';
import React from 'react';

import ProTip from 'components/Onboarding/ProTip';
import { TooltipContent, TooltipContentBold } from 'components/Onboarding/TooltipContent';
import { pxToRem } from 'components/theme/typography';

export const ONBOARDING = {
    BODY: { TARGET: 'body', TOOLTIP_CONTENT: "Let's take a quick tour to get you started." },
    HOME: {
        TARGET: 'home',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        This is where you can see all the <TooltipContentBold text="scheduled tasks" /> and{' '}
                        <TooltipContentBold text="add PRN" /> or <TooltipContentBold text="notes!" />
                    </>
                }
            />
        ),
    },
    RESIDENTS: {
        TARGET: 'residents',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        Here is where to find <TooltipContentBold text="all your residents" />, see{' '}
                        <TooltipContentBold text="their care plan" />, <TooltipContentBold text="notes" /> and other
                        info.
                    </>
                }
            />
        ),
    },
    MY_REWARDS: {
        TARGET: 'myRewards',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        You can see <TooltipContentBold text="all your rewards" /> from this navigation, and we have{' '}
                        <TooltipContentBold text="tips to earn more rewards in 4 ways!" />
                    </>
                }
            />
        ),
    },
    OPEN_DOCUMENT_TASKS: {
        TARGET: 'open-document-tasks',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        Let&apos;s show you <TooltipContentBold text="how to document scheduled tasks" />. Please click{' '}
                        <TooltipContentBold text='on "Got it"' /> and then{' '}
                        <TooltipContentBold text="on the resident row" /> to see the tasks.
                    </>
                }
            />
        ),
    },
    DOCUMENT_TASKS: {
        TARGET: 'document-tasks',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        Here is where you will <TooltipContentBold text="document scheduled tasks" />.
                    </>
                }
            />
        ),
    },
    CHECK_TASK_BTN: {
        TARGET: 'check-task-btn',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        Click this button <TooltipContentBold text="“Check”" /> if the task is done!
                    </>
                }
            />
        ),
    },
    DOCUMENT_TASKS_PRO_TIP: {
        TARGET: 'document-tasks-pro-tip',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        <ProTip />
                        <Box
                            sx={{
                                mt: pxToRem(8),
                            }}
                        >
                            You can select multiple tasks and complete them all at once, but{' '}
                            <Box
                                component="span"
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                PLEASE
                            </Box>{' '}
                            make sure the tasks are really completed!
                        </Box>
                    </>
                }
            />
        ),
    },
    DOCUMENT_ALL_TASKS_PRO_TIP: {
        TARGET: 'document-all-tasks-pro-tip',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        <ProTip />
                        <Box
                            sx={{
                                mt: pxToRem(8),
                            }}
                        >
                            You can even directly check all the tasks for the resident by checking this checkbox. All
                            the tasks will be selected and you can <TooltipContentBold text="mark as complete" /> or{' '}
                            <TooltipContentBold text="mark as refused" color="#BF2828" /> all at once. But{' '}
                            <Box
                                component="span"
                                sx={{
                                    fontWeight: 700,
                                }}
                            >
                                PLEASE
                            </Box>{' '}
                            make sure the tasks are really completed!
                        </Box>
                    </>
                }
            />
        ),
    },
    REFUSE_TASK_BTN: {
        TARGET: 'refuse-task-btn',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        Let&apos;s show you <TooltipContentBold text="how to document tasks as refused" />. Please click{' '}
                        <TooltipContentBold text='on "Got it"' /> and then <TooltipContentBold text='on the "X"' />.
                    </>
                }
            />
        ),
    },
    REFUSE_TASK_REASON: {
        TARGET: 'refuse-task-reason',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        And choose a reason from the options to explain why the task is refused. Please click{' '}
                        <TooltipContentBold text='on "Got it"' /> and then{' '}
                        <TooltipContentBold text="select any of the options" />.
                    </>
                }
            />
        ),
    },
    REFUSE_TASK_COMMENT: {
        TARGET: 'refuse-task-comment',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        Next, add a comment to provide further details or simply explain why the task can&apos;t be
                        done.
                        <Divider sx={{ my: pxToRem(16) }} />
                        <ProTip />
                        <Box
                            sx={{
                                mt: pxToRem(8),
                            }}
                        >
                            <TooltipContentBold text="You can use voice to text." /> It will automagically turn your
                            voice into texts, or even translate Spanish, Chinese, Portuguese, etc to English.
                        </Box>
                    </>
                }
            />
        ),
    },
    REFUSE_TASK_COMMENT_SUBMIT: {
        TARGET: 'refuse-task-comment-submit',
        TOOLTIP_CONTENT: (
            <TooltipContent
                content={
                    <>
                        Don’t forget to click the <TooltipContentBold text="“Submit”" /> button below if its done! This
                        will <TooltipContentBold text="accumulate a point" /> to your{' '}
                        <TooltipContentBold text="rewards page" />.
                    </>
                }
            />
        ),
    },
};
