import { ShiftOption } from '@alliehealth/utils/dist/shifts';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import langDictionary from 'app-strings';
import React from 'react';

import { CustomSelect } from 'components/Custom';

import { MOBILE_SELECTOR_STYLES, SELECTOR_STYLES } from './constants';

type Props = {
    selectedShiftId: number;
    currentShiftId?: number;
    shiftOptions: {
        shiftDayDate: string;
        shift: ShiftOption;
    }[];
    fullWidth?: boolean;
    width?: number | string;
    onChange: (newShift: string) => void;
    isDialog?: boolean;
};

export const ShiftSelector = (props: Props) => {
    const { selectedShiftId, currentShiftId, shiftOptions, fullWidth, width = '48%', onChange, isDialog } = props;
    const { home } = langDictionary;

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const handleFilterChange = (newValue: string) => {
        onChange(newValue);
    };

    const dropdownOptions = shiftOptions.map(({ shift: { id, name } }) => ({
        label: name + (id === currentShiftId ? ' (current)' : ''),
        value: String(id),
    }));

    return (
        <Box sx={{ width }}>
            <CustomSelect
                id="shift"
                label={home.shift}
                value={selectedShiftId}
                options={dropdownOptions}
                fullWidth={fullWidth}
                onChange={handleFilterChange}
                sx={isMobile && !isDialog ? { ...MOBILE_SELECTOR_STYLES, ...SELECTOR_STYLES } : SELECTOR_STYLES}
            />
        </Box>
    );
};
