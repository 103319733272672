import { ResourceActions, ResourceType } from 'permissions/constants';
import { usePermissions } from 'permissions/utils';
import { usePostHog } from 'posthog-js/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router';

import { ReduxStore } from 'types/redux';

import { useGeofencing } from '../hooks/useGeofencing';
import { usePermissionsQuery } from 'api/queries/permissions';
import GeolocationInfo from 'components/Geolocation/GeolocationInfo';
import OutsideGeofence from 'components/Geolocation/OutsideGeofence';
import { isPostHogEnabled } from 'helpers/PostHogHandler';

import NavigationLoading from './NavigationLoading';
import PermissionsLoading from './PermissionsLoading';

const AccessControl = ({
    requiresAuthentication,
    requiresAffiliation,
    requiresGeofencing,
    permission,
    featureFlag,
    children,
}: {
    requiresAuthentication?: boolean;
    requiresAffiliation?: true;
    requiresGeofencing?: true;
    permission?: [ResourceType, ResourceActions<ResourceType>];
    featureFlag?: string; // Open by default if PostHog is not set up (e.g. in dev)
    children?: React.ReactNode;
}) => {
    const path = window.location.pathname;

    const { isSignedIn, sessionData, isPostHogLoaded } = useSelector((state: ReduxStore) => state.session);
    const { companyId, regionId, branchId } = sessionData;
    const isAffiliated = Boolean(companyId || regionId || branchId);

    const { isSuccess: isPermitLoaded } = usePermissionsQuery();

    const posthog = usePostHog();
    const hasPermission = usePermissions();
    const geofencingState = useGeofencing(branchId);

    // isPostHogLoaded, isSignedIn and authentication can be null/undefined if unknown/unspecified,
    // so we need to make explicit comparisons

    if (featureFlag && isPostHogEnabled) {
        if (!isPostHogLoaded) return <NavigationLoading text="Loading features..." />;
        else if (!posthog.isFeatureEnabled(featureFlag)) return <Navigate to="/" replace />;
    }

    switch (isSignedIn) {
        case true:
            if (requiresAuthentication === false) return <Navigate to="/" replace />;

            if (requiresAffiliation && !isAffiliated) return <Navigate to="/join-community" replace />;

            // The following checks require permissions, so they must be loaded beforehand
            if (isAffiliated && !isPermitLoaded) return <PermissionsLoading />;

            // Instead of showing a 403 page, we redirect to home
            if (permission && !hasPermission(permission[0], permission[1])) return <Navigate to="/" replace />;

            if (requiresGeofencing) {
                switch (geofencingState) {
                    case 'unknown':
                        return <NavigationLoading text="Loading your geolocation..." />;
                    case 'prompt':
                        return <GeolocationInfo />;
                    case 'outside':
                        return <OutsideGeofence />;
                    case 'not-applicable':
                    case 'inside':
                    default:
                        break;
                }
            }
            break;

        case false:
            if (requiresAuthentication === true) return <Navigate to={`/login?redirect=${path}`} replace />;
            break;

        case null:
        default:
            return <NavigationLoading text="Loading your account..." />;
    }

    return children ?? <Outlet />;
};

export default AccessControl;
