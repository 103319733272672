import { CircularProgress } from '@mui/material';
import React from 'react';

import { CenteredText, FullScreenContainer } from './shared';

const NavigationLoading = ({ text }: { text?: string }) => (
    <FullScreenContainer>
        <CircularProgress />
        {!!text && <CenteredText>{text}</CenteredText>}
    </FullScreenContainer>
);

export default NavigationLoading;
