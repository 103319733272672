import { omit } from 'lodash';
import React, { useMemo, useState } from 'react';

import { DailyTasksShiftDetail, ResidentTasks, TaskLoadingState, TaskToUpdate } from 'types/dailyTasks';

import { TASK_STATUS, TASK_STATUS_ID_TO_TASK_STATUS } from '@alliehealth/utils/dist/constants/task-records.constants';
import { useUpdateDailyTasks } from 'api/queries/tasks/dailyTasks';
import EditTaskStatusDialog from 'components/Shared/EditTaskStatusDialog';
import { AddTaskNotesDialog } from 'components/Shared/Task/AddTaskNotesDialog';
import { TaskNotesViewDialog } from 'components/Shared/Task/TaskNotesViewDialog';
import { DIALOG_TYPE } from 'constants/shared';

import { MultiSelectProvider } from './MultiSelectProvider';
import { ResidentRow } from './ResidentRow';

type Props = {
    branchId: number;
    residentTasksList: ResidentTasks[];
};

export const ResidentRows = (props: Props) => {
    const { residentTasksList, branchId } = props;

    const [loadingTaskStates, setLoadingTaskStates] = useState<{ [key: number]: TaskLoadingState }>({});

    const [viewTaskIdNotes, setViewTaskIdNotes] = useState<number | null>(null);

    const [showTaskIdAddNotesDialog, setShowTaskIdAddNotesDialog] = useState<number | null>(null);

    const [showTaskIdRejectDialog, setShowTaskIdRejectDialog] = useState<number | null>(null);

    const [taskIdCompletionOptions, setTaskIdCompletionOptions] = useState<{
        [key: string]: number;
    }>({});

    const updateTaskMutation = useUpdateDailyTasks();

    const taskIdToTask: Record<number, DailyTasksShiftDetail> = useMemo(
        () =>
            residentTasksList.reduce((acc, { tasks }) => {
                tasks.forEach((task) => {
                    acc[task.dailyTaskRecordId] = task;
                });

                return acc;
            }, {}),
        [residentTasksList]
    );

    const onToggleTaskNotes = (taskId = null) => {
        setViewTaskIdNotes(taskId);
    };

    const onToggleEditAddTaskNotes = (taskId = null) => {
        setShowTaskIdAddNotesDialog(taskId);
    };

    const onSubmitEditAddTaskNotes = (taskNotes: string) => {
        // submit note and refresh tasks
        void onUpdateConfirmedTask(showTaskIdAddNotesDialog!, { taskNotes });
        setShowTaskIdAddNotesDialog(null);
    };

    const onToggleRejectDialog = (taskId = null) => {
        setShowTaskIdRejectDialog(taskId);
    };

    const onRejectDialogSubmit = (taskStatusId: number, notes: string) => {
        void onUpdateConfirmedTask(showTaskIdRejectDialog!, {
            taskStatusId,
            taskNotes: notes,
        });
        setShowTaskIdRejectDialog(null);
    };

    const onCompletionOptionsChange = (taskId: number, completionValue: number) => {
        setTaskIdCompletionOptions({
            ...taskIdCompletionOptions,
            [taskId]: completionValue,
        });
    };

    const onUpdateConfirmedTask = async (
        taskId: number,
        { taskStatusId = null, taskNotes = null }: { taskStatusId?: number | null; taskNotes?: string | null }
    ) => {
        const originalTask = taskIdToTask[taskId];
        const updatedTaskStatusId = taskStatusId || originalTask.taskStatusId;
        const updatedTaskNotes = taskNotes || originalTask.caregiverNotes;

        const taskStatus = TASK_STATUS_ID_TO_TASK_STATUS[updatedTaskStatusId] as keyof typeof TASK_STATUS;
        const tasksToUpdate: TaskToUpdate[] = [];

        tasksToUpdate.push({
            taskId,
            taskStatusId: updatedTaskStatusId,
            caregiverNotes: updatedTaskNotes,
        });

        setLoadingTaskStates((prevState) => ({
            ...prevState,
            [taskId]: taskStatus === TASK_STATUS.COMPLETED ? 'confirm' : 'reject',
        }));

        updateTaskMutation.mutate({ tasks: tasksToUpdate, branchId });

        setLoadingTaskStates((prevState) => omit(prevState, taskId));
    };

    if (residentTasksList.length === 0) {
        return null;
    }

    return (
        <MultiSelectProvider
            render={(openedResidentId, onResidentToggle) => (
                <>
                    {residentTasksList.map((residentTasks) => (
                        <ResidentRow
                            key={residentTasks.id}
                            isOpen={openedResidentId === residentTasks.id}
                            residentTasks={residentTasks}
                            loadingTaskStates={loadingTaskStates}
                            onResidentToggle={onResidentToggle}
                            onToggleTaskNotes={onToggleTaskNotes}
                            onToggleEditAddTaskNotes={onToggleEditAddTaskNotes}
                            onToggleRejectDialog={onToggleRejectDialog}
                            onCompletionOptionsChange={onCompletionOptionsChange}
                            taskIdCompletionOptions={taskIdCompletionOptions}
                        />
                    ))}
                    <TaskNotesViewDialog
                        isOpen={viewTaskIdNotes !== null}
                        taskNote={viewTaskIdNotes ? taskIdToTask[viewTaskIdNotes].taskNotes : ''}
                        onClose={() => onToggleTaskNotes()}
                    />
                    <AddTaskNotesDialog
                        isOpen={showTaskIdAddNotesDialog !== null}
                        onClose={() => onToggleEditAddTaskNotes()}
                        onSubmit={onSubmitEditAddTaskNotes}
                        taskNote={showTaskIdAddNotesDialog ? taskIdToTask[showTaskIdAddNotesDialog].caregiverNotes : ''}
                    />
                    <EditTaskStatusDialog
                        isOpen={showTaskIdRejectDialog !== null}
                        onClose={onToggleRejectDialog}
                        onSubmit={onRejectDialogSubmit}
                        selectedRecord={showTaskIdRejectDialog ? taskIdToTask[showTaskIdRejectDialog] : undefined}
                        dialogType={DIALOG_TYPE.edit}
                    />
                </>
            )}
        />
    );
};
