import { useMutation } from '@tanstack/react-query';
import { api } from 'api';
import { AxiosError } from 'axios';

import { useAppDispatch } from 'constants/redux';
import { throwError } from 'redux/actions/messages';

export const useEmailUnsubscribeMutation = () => {
    const dispatch = useAppDispatch();

    return useMutation({
        mutationFn: async (params: { userId: number; workflowName: string; emailId: string; token: string }) => {
            await api.post('/user-subscription/email-unsubscribe', params);
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};

export const useEmailSubscribeMutation = () => {
    const dispatch = useAppDispatch();

    return useMutation({
        mutationFn: async (params: { userId: number; workflowName: string; emailId: string; token: string }) => {
            await api.post('/user-subscription/email-subscribe', params);
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
