import { useMutation } from '@tanstack/react-query';
import { api } from 'api';
import { AxiosError } from 'axios';

import { DocumentationActionsParams, DocumentationActionsResponse } from 'types/dailyTasks';

import { useAppDispatch } from 'constants/redux';
import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { useToken } from 'lib/common';
import { throwError } from 'redux/actions/messages';

export const useDocumentationActionsQuery = (params: DocumentationActionsParams) => {
    const { branchId, taskIds } = params;
    const token = useToken();

    return useErrorHandledQuery<DocumentationActionsResponse>(
        ['documentation-actions', params],
        async (): Promise<DocumentationActionsResponse> => {
            const { data } = await api.get(`/tasks/documentation-actions`, {
                headers: { authorization: token },
                params: {
                    branch_id: branchId,
                    task_ids: taskIds.join(','),
                },
            });
            return data.response;
        },
        { enabled: !!taskIds.length && !!branchId, staleTime: 300000 }
    );
};

export const useAssistLevelChangeMutation = () => {
    const dispatch = useAppDispatch();
    const token = useToken();

    return useMutation({
        mutationFn: async (params: { residentId: number; carePlanId: number; branchId: number }) => {
            await api.post(
                `/tasks/assist-level`,
                {
                    resident_id: params.residentId,
                    care_plan_id: params.carePlanId,
                },
                {
                    headers: { authorization: token },
                    params: {
                        branch_id: params.branchId,
                    },
                }
            );
        },
        onError: (error) => {
            const customError = error as AxiosError;

            dispatch(throwError(customError));
            throw customError;
        },
        retry: false,
    });
};
