import { Box, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import React, { ReactNode } from 'react';
import { Outlet } from 'react-router';

import DesktopDrawer from 'components/Layout/DesktopDrawer';
import DesktopHeader from 'components/Layout/DesktopHeader';
import MobileBottomNavigation from 'components/Layout/MobileBottomNavigation';
import MobileDrawer from 'components/Layout/MobileDrawer';
import MobileHeader from 'components/Layout/MobileHeader';
import { pxToRem } from 'components/theme/typography';

import { DESKTOP_DRAWER_WIDTH, MOBILE_BOTTOM_NAVIGATION_HEIGHT, MOBILE_HEADER_HEIGHT } from './shared';

const MainContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        height: '100%',
    })
);

const BodyContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: { xs: '100%', lg: `calc(100% - ${pxToRem(DESKTOP_DRAWER_WIDTH)})` },
        height: '100%',
        pt: 'env(safe-area-inset-top)',
    })
);

const OutletContainer = ({
    children,
    mobileHeader,
    mobileBottomNavigation,
}: {
    children: ReactNode;
    mobileHeader: boolean;
    mobileBottomNavigation: boolean;
}) => (
    <Box
        sx={{
            height: '100%',
            pt: { xs: mobileHeader ? pxToRem(MOBILE_HEADER_HEIGHT) : 0, lg: 0 },
            pb: { xs: mobileBottomNavigation ? pxToRem(MOBILE_BOTTOM_NAVIGATION_HEIGHT) : 0, lg: 0 },
        }}
    >
        {children}
    </Box>
);

const Scaffold = ({
    desktopDrawer,
    desktopHeader,
    mobileDrawer,
    mobileHeader,
    mobileBottomNavigation,
    children,
}: {
    desktopDrawer?: true;
    desktopHeader?: true;
    mobileDrawer?: true;
    mobileHeader?: true;
    mobileBottomNavigation?: true;
    children?: React.ReactNode;
}) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

    return (
        <MainContainer>
            {!isMobile && desktopDrawer && <DesktopDrawer />}
            <BodyContainer>
                {isMobile && mobileDrawer && <MobileDrawer />}
                {isMobile ? mobileHeader && <MobileHeader /> : desktopHeader && <DesktopHeader />}
                <OutletContainer mobileHeader={!!mobileHeader} mobileBottomNavigation={!!mobileBottomNavigation}>
                    {children ?? <Outlet />}
                </OutletContainer>
                {isMobile && mobileBottomNavigation && <MobileBottomNavigation />}
            </BodyContainer>
        </MainContainer>
    );
};

export default Scaffold;
