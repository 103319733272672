import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import React from 'react';

import { FallsSectionDetail } from 'types/operationsDashboard';

import { formatDateTime } from '../constants';

type Props = {
    rows: FallsSectionDetail[];
};

export function FallsCollapsibleComponent({ rows }: Props) {
    const headers = ['Occurred On', 'Falls in Last 30d', 'Reporter Name'];

    function renderRow(item: FallsSectionDetail) {
        return (
            <TableRow key={[item.fallDateTime, item.last30FallCount, item.reporterName].join('')}>
                <TableCell align="left">{formatDateTime(item.fallDateTime)}</TableCell>
                <TableCell align="left">{item.last30FallCount}</TableCell>
                <TableCell align="left">{item.reporterName || '-'}</TableCell>
            </TableRow>
        );
    }

    return (
        <TableContainer>
            <Table aria-label="details row">
                <TableHead>
                    <TableRow>
                        {headers.map((header) => (
                            <TableCell align="left" key={header}>
                                {header}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>{rows.map(renderRow)}</TableBody>
            </Table>
        </TableContainer>
    );
}
