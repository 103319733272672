import { Box, Card, Typography } from '@mui/material';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import { useReactToPrint } from 'react-to-print';

import { OperationsPeriod } from 'types/operationsDashboard';
import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';
import { Section } from 'pages/OperationsDashboard/constants';

import { ModuleSubtitle } from './ModuleSubtitle';
import { REACT_PRINT_CLASSNAMES } from './constants';

const customPrint = (printWindow: HTMLIFrameElement, documentTitle: string) => {
    // since we are creating a custom print function we need to handle setting the document title manually
    // see react-to-print-implementation: https://github.com/MatthewHerbst/react-to-print/blob/d3fb22c99bf087054c547066c68ad7b0db4c63c1/src/components/ReactToPrint.tsx#L50-L58
    return new Promise<void>((resolve) => {
        // set document title in Chrome
        printWindow.ownerDocument.title = documentTitle;

        // set document title in Firefox and Safari
        if (printWindow.contentDocument) {
            printWindow.contentDocument.title = documentTitle;
        }

        const printContent = printWindow.contentDocument || printWindow.contentWindow?.document;

        // remove the scroll on the module details list
        const printedModuleDetails = printContent!.querySelector<HTMLElement>(
            `.${REACT_PRINT_CLASSNAMES.MODULE_DETAILS}`
        );
        const originModuleDetails = document.querySelector(`.${REACT_PRINT_CLASSNAMES.MODULE_DETAILS}`);

        printedModuleDetails!.scrollTop = originModuleDetails!.scrollTop;
        printedModuleDetails!.style.overflow = 'visible';
        printedModuleDetails!.style.maxHeight = 'fit-content';

        // Put a margin around the module in the print out
        const printedModuleContainer = printContent!.querySelector<HTMLElement>(`.${REACT_PRINT_CLASSNAMES.MODULE}`);
        printedModuleContainer!.style.margin = '30px';

        // hide the print button in the print out
        const printedPrintButton = printContent!.querySelector<HTMLElement>(
            `.${REACT_PRINT_CLASSNAMES.MODULE_PRINT_BUTTON}`
        );
        printedPrintButton!.style.display = 'none';

        printWindow.contentWindow!.print();
        resolve();
    });
};

type Props = {
    dataType: Section;
    overallStat: string;
    operationsPeriod: OperationsPeriod;
    sectionTitle: string;
    miniDisplay?: 'chart' | 'table';
    renderChart: () => JSX.Element;
    renderTable: () => JSX.Element;
    onClick?: () => void;
};

export const SectionModule = ({
    dataType,
    overallStat,
    sectionTitle,
    miniDisplay,
    operationsPeriod,
    renderChart,
    renderTable,
    onClick,
}: Props) => {
    const { selectedStartDate } = useSelector((state: ReduxStore) => state.opsDashboard);

    const contentToPrint = useRef(null);
    const handlePrint = useReactToPrint({
        removeAfterPrint: true,
        print: (printWindow: HTMLIFrameElement) => customPrint(printWindow, `${sectionTitle} - ${selectedStartDate}`),
    });

    if (miniDisplay === 'table') {
        return renderTable();
    }

    return (
        <Box
            sx={{
                '&:not(:first-of-type)': {
                    mt: { lg: 0, xs: pxToRem(24) },
                },
            }}
            onClick={onClick}
        >
            <Typography
                sx={{
                    fontSize: { lg: pxToRem(20), xs: pxToRem(16) },
                    fontWeight: 'bold',
                    lineHeight: 1.2,
                    mb: pxToRem(14),
                }}
            >
                {sectionTitle}
            </Typography>
            <Card
                variant="outlined"
                sx={{
                    borderRadius: '12px',
                    display: 'grid',
                    gridTemplateRows: 'min-content 1fr',
                }}
                ref={contentToPrint}
                className={REACT_PRINT_CLASSNAMES.MODULE}
            >
                <ModuleSubtitle
                    amount={overallStat}
                    subtitle={
                        <>
                            <Box component="span" sx={{ textTransform: 'lowercase' }}>
                                {sectionTitle}{' '}
                                {operationsPeriod === 'day' ? (
                                    <Box
                                        component="span"
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        yesterday
                                    </Box>
                                ) : (
                                    'in '
                                )}
                                {operationsPeriod !== 'day' && (
                                    <Box
                                        component="span"
                                        sx={{
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        last {operationsPeriod}
                                    </Box>
                                )}
                            </Box>
                        </>
                    }
                    onPrint={() => handlePrint(null, () => contentToPrint.current)}
                />

                <Box
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: { lg: `1fr minmax(400px, 1fr)` },
                    }}
                >
                    {(miniDisplay === 'chart' || !miniDisplay) && (
                        <Box
                            sx={
                                !miniDisplay
                                    ? {
                                          borderRight: '1px solid #ECECF3',
                                          p: pxToRem(20),
                                          display: { lg: 'grid', xs: 'block' },
                                          placeItems: 'center',
                                      }
                                    : {
                                          p: pxToRem(10),
                                          pb: pxToRem(5),
                                      }
                            }
                        >
                            {renderChart()}
                        </Box>
                    )}
                    {!miniDisplay && (
                        <Box
                            className={REACT_PRINT_CLASSNAMES.MODULE_DETAILS}
                            sx={{
                                maxHeight: dataType !== 'DOCUMENTATION_RATE' ? pxToRem(376) : undefined,
                                overflowY: 'auto',
                                p: pxToRem(20),
                            }}
                        >
                            {renderTable()}
                        </Box>
                    )}
                </Box>
            </Card>
        </Box>
    );
};
