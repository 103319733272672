import { Box } from '@mui/material';
import React, { useMemo } from 'react';

import { ResidentTasks, TaskLoadingState } from 'types/dailyTasks';

import { useCompanyCarePlansListQuery } from 'api/queries/companyCarePlans';
import { Selector } from 'components/Shared/Selector';
import { ResidentRowTask } from 'components/Shared/Task/TaskRow/ResidentRowTask';
import { pxToRem } from 'components/theme/typography';
import { ONBOARDING } from 'constants/onboarding';

import { AddedInfoActions } from './resident/AddedInfoActions';
import { BulkCompleteTray } from './resident/BulkCompleteTray';
import { CollapseIcon } from './resident/CollapseIcon';
import { ResidentDetails } from './resident/ResidentDetails';
import { ResidentPhoto } from './resident/ResidentPhoto';
import { TaskCounter } from './resident/TaskCounter';

type Props = {
    isOpen: boolean;
    isSelected: boolean;
    residentTasks: ResidentTasks;
    selectedTaskIds: Set<number>;
    loadingTaskStates: { [key: number]: TaskLoadingState };
    loadingBulkState: TaskLoadingState;
    taskIdCompletionOptions: { [key: number]: number };
    taskIdNotes: { [key: number]: string };
    onResidentToggle: (number) => void;
    onResidentSelectToggle: (number) => void;
    onResidentTaskSelectToggle: (number) => void;
    onToggleTaskNotes: (number) => void;
    onToggleEditAddTaskNotes: (number) => void;
    onToggleRejectDialog: (number) => void;
    onCompletionOptionsChange: (taskId: number, completionValue: number) => void;
    onConfirmTask: (taskId: number, taskStatusId: number) => void;
    confirmSelectedTasks: (taskStatusId: number) => void;
    date: string;
    shift: number;
    branchId: number;
};

export const ResidentRow = (props: Props) => {
    const {
        isOpen,
        isSelected,
        residentTasks,
        selectedTaskIds,
        loadingTaskStates,
        loadingBulkState,
        taskIdCompletionOptions,
        taskIdNotes,
        onResidentToggle,
        onResidentSelectToggle,
        onResidentTaskSelectToggle,
        onToggleTaskNotes,
        onToggleEditAddTaskNotes,
        onToggleRejectDialog,
        onCompletionOptionsChange,
        onConfirmTask,
        confirmSelectedTasks,
        shift,
        branchId,
    } = props;
    const { id, firstName, lastName, photo, roomNumber, isOnHospice, tasks } = residentTasks;

    const onToggleResident = () => onResidentToggle(id);
    const onSelectToggle = () => onResidentSelectToggle(id);

    const { data: carePlanData } = useCompanyCarePlansListQuery(branchId);

    const carePlanIdsForKeyService = (keyService: string) =>
        carePlanData
            ?.filter((carePlan) => carePlan.keyService === keyService)
            .map((carePlan) => carePlan.companyCarePlanId) ?? [];

    const showerCarePlanIds = useMemo(() => carePlanIdsForKeyService('SHOWERING'), [carePlanData]);
    const laundryCarePlanIds = useMemo(() => carePlanIdsForKeyService('LAUNDRY'), [carePlanData]);

    const needsShower = tasks.some((task) => showerCarePlanIds.includes(task.companyCarePlanId));
    const needsLaundry = tasks.some((task) => laundryCarePlanIds.includes(task.companyCarePlanId));

    const showBulkCompleteTray = isSelected && selectedTaskIds.size > 0;

    const areAllTasksSelected = tasks.length === selectedTaskIds.size;

    return (
        <Box
            id={ONBOARDING.OPEN_DOCUMENT_TASKS.TARGET}
            sx={{
                borderBottom: 1,
                borderBottomColor: '#DEDEE0',
                paddingBottom: isOpen && showBulkCompleteTray ? 0 : pxToRem(16),
                paddingTop: pxToRem(16),
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: isOpen ? pxToRem(16) : 0,
                }}
            >
                <Selector
                    id={ONBOARDING.DOCUMENT_ALL_TASKS_PRO_TIP.TARGET}
                    isSelected={isSelected}
                    onSelect={onSelectToggle}
                    areAllTasksSelected={areAllTasksSelected}
                    isResidentSelector
                />
                <ResidentPhoto residentId={id} firstName={firstName} lastName={lastName} photo={photo} />
                <Box
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                    }}
                    onClick={onToggleResident}
                >
                    <ResidentDetails
                        firstName={firstName}
                        lastName={lastName}
                        roomNumber={roomNumber}
                        needsShower={needsShower}
                        needsLaundry={needsLaundry}
                        isOnHospice={isOnHospice}
                    />
                    <TaskCounter count={tasks.length} />
                    <CollapseIcon isCollapsed={!isOpen} />
                </Box>
            </Box>
            {isOpen && <AddedInfoActions residentId={id} shift={shift} />}
            {isOpen &&
                tasks.map((task) => {
                    const onToggle = () => onResidentTaskSelectToggle(task.dailyTaskRecordId);
                    const onToggleNotes = () => onToggleTaskNotes(task.dailyTaskRecordId);
                    const onToggleEditNotes = () => onToggleEditAddTaskNotes(task.dailyTaskRecordId);
                    const onToggleReject = () => onToggleRejectDialog(task.dailyTaskRecordId);
                    const completionOptionValue = taskIdCompletionOptions[task.dailyTaskRecordId];
                    const onConfirm = () => onConfirmTask(task.dailyTaskRecordId, 2);
                    return (
                        <ResidentRowTask
                            key={task.dailyTaskRecordId}
                            isSelected={selectedTaskIds.has(task.dailyTaskRecordId)}
                            onToggle={onToggle}
                            taskName={task.taskName}
                            taskTime={task.taskTime}
                            taskType={task.taskType}
                            taskNotes={task.taskNotes}
                            userTaskNotes={taskIdNotes[task.dailyTaskRecordId]}
                            taskCompletionOptions={task.taskCompletionOptions}
                            caregiver={task.caregiver}
                            completedDateTime={task.taskStatusDateTime ?? undefined}
                            onToggleNotes={onToggleNotes}
                            onToggleEditAddTaskNotes={onToggleEditNotes}
                            onToggleRejectDialog={onToggleReject}
                            onCompletionOptionsChange={(completionValue: number) =>
                                onCompletionOptionsChange(task.dailyTaskRecordId, completionValue)
                            }
                            completionOptionValue={completionOptionValue}
                            loadingState={loadingTaskStates[task.dailyTaskRecordId] ?? null}
                            onConfirmTask={onConfirm}
                        />
                    );
                })}
            {isOpen && showBulkCompleteTray && (
                <BulkCompleteTray
                    selectedTaskIds={[...selectedTaskIds]}
                    onToggleRejectDialog={onToggleRejectDialog}
                    confirmSelectedTasks={confirmSelectedTasks}
                    loadingBulkState={loadingBulkState}
                />
            )}
        </Box>
    );
};
