import { DailyTasksShiftDetail, ResidentTasks } from 'types/dailyTasks';

export const ALL_ZONES_ID = -1;
export const NO_RESIDENT_SELECTED_ID = '-1';

interface FilterTasksByNameParams {
    zoneId: number;
    tasks: DailyTasksShiftDetail[];
    searchValue: string;
}

export function filterTasksByName(params: FilterTasksByNameParams): DailyTasksShiftDetail[] {
    const { searchValue, zoneId, tasks } = params;

    if (!searchValue) return tasks;

    const searchInLowerCase = searchValue.toLowerCase();

    return tasks.filter((task) => {
        const residentFullName = `${task.resident.firstName} ${task.resident.lastName}`.toLowerCase();

        return (
            (task.resident.firstName.toLowerCase().includes(searchInLowerCase) ||
                task.resident.lastName.toLowerCase().includes(searchInLowerCase) ||
                residentFullName.includes(searchInLowerCase) ||
                task.resident.roomNumber?.toLowerCase().includes(searchInLowerCase)) &&
            (zoneId === ALL_ZONES_ID || task.zoneId === zoneId)
        );
    });
}

export function groupTasksByResidentId(tasks: DailyTasksShiftDetail[]): Record<number, ResidentTasks> {
    const residentsTasks: Record<number, ResidentTasks> = {};

    tasks.forEach((task) => {
        if (!residentsTasks[task.resident.id]) {
            residentsTasks[task.resident.id] = {
                ...task.resident,
                tasks: [],
            };
        }

        residentsTasks[task.resident.id].tasks.push(task);
    });

    return residentsTasks;
}
