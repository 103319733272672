import { Box, Button } from '@mui/material';
import { debounce } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import React, { useState } from 'react';
import { FaMobile } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { ReduxStore } from 'types/redux';
import { SessionData } from 'types/session';

import { useLoginInitMutation, useLoginVerifyMutation } from 'api/queries/auth/login';
import AuthButton from 'components/Auth/AuthButton';
import AuthCtaLink from 'components/Auth/AuthCtaLink';
import AuthIcon from 'components/Auth/AuthIcon';
import AuthTitle from 'components/Auth/AuthTitle';
import BackButton from 'components/Auth/BackButton';
import CodeInput from 'components/Auth/CodeInput';
import FormContainer from 'components/Auth/FormContainer';
import MainContainer from 'components/Auth/MainContainer';
import { pxToRem } from 'components/theme/typography';
import { useAppDispatch } from 'constants/redux';
import { VERIFICATION_DIGITS_LENGTH } from 'constants/shared';
import { signIn } from 'lib/auth';
import PageStructure from 'pages/PageStructure';
import { hideAlert } from 'redux/actions/messages';

const CodeVerificationStep = () => {
    const [verificationCode, setVerificationCode] = useState('');

    const { mutate: loginVerifyMutation, isLoading } = useLoginVerifyMutation();

    const { mutate: loginInitMutation } = useLoginInitMutation();

    const navigate = useNavigate();

    const { sessionData } = useSelector((state: ReduxStore) => state.session);

    const dispatch = useAppDispatch();

    const posthog = usePostHog();

    const handleCodeChange = (code: string) => {
        setVerificationCode(code);

        if (code.length === 4) {
            debouncedHandleVerifyCode(code);
        }
    };

    const searchParams = new URLSearchParams(window.location.search);
    const phoneNumber = decodeURIComponent(searchParams.get('phoneNumber') || '');
    const redirect = searchParams.get('redirect') || '';
    const isOptional = searchParams.get('optional') === 'true';

    const handleVerifyCode = (code?: string) => {
        dispatch(hideAlert());

        loginVerifyMutation(
            {
                phoneNumber: phoneNumber || '',
                code: code || verificationCode,
            },
            {
                onSuccess: (data: SessionData) => {
                    signIn(data, posthog);
                    if (redirect) navigate(redirect, { replace: true });
                },
            }
        );
    };

    const debouncedHandleVerifyCode = debounce(handleVerifyCode, 1000);

    const handleResendCodeClick = () => {
        setVerificationCode('');
        loginInitMutation({
            phoneNumber,
        });
    };

    const handleSkip = () => {
        dispatch(hideAlert());

        signIn(sessionData, posthog);
        if (redirect) navigate(redirect, { replace: true });
    };

    return (
        <PageStructure>
            <MainContainer>
                <FormContainer>
                    <Box
                        sx={{
                            mb: {
                                lg: pxToRem(44),
                            },
                            pt: 'env(safe-area-inset-top)',
                        }}
                    >
                        <BackButton returnToUrl={'/login'} />
                        <AuthIcon icon={<FaMobile size={36} />} />
                        <Box
                            sx={{
                                mt: {
                                    xs: pxToRem(48),
                                },
                                mb: {
                                    xs: pxToRem(24),
                                },
                            }}
                        >
                            <AuthTitle
                                title="Code Verification"
                                subtitle="A verification code has been sent to your phone
                            number"
                                align="center"
                            />
                        </Box>
                        <CodeInput onCodeChange={handleCodeChange} code={verificationCode} />
                        <AuthCtaLink
                            question="Didn't receive the code?"
                            linkLabel="Send again."
                            onClick={handleResendCodeClick}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: isOptional ? '40% 1fr' : '1fr',
                            gap: pxToRem(12),
                        }}
                    >
                        {isOptional && (
                            <Button
                                variant="outlined"
                                onClick={handleSkip}
                                fullWidth
                                disableRipple
                                type="button"
                                sx={{
                                    py: pxToRem(16),
                                    fontSize: {
                                        xs: pxToRem(16),
                                    },
                                    lineHeight: {
                                        xs: 1.5,
                                    },
                                    boxShadow: 'none',
                                    backgroundColor: 'transparent',
                                    '&:hover, &:focus': {
                                        boxShadow: 'none',
                                    },
                                    '&:focus-visible': {
                                        outline: (theme) => `2px solid ${theme.palette.primary.main}`,
                                        outlineOffset: '-3px',
                                    },
                                }}
                            >
                                Skip
                            </Button>
                        )}
                        <AuthButton
                            label="Verify"
                            onClick={handleVerifyCode}
                            isDisabled={verificationCode.length !== VERIFICATION_DIGITS_LENGTH}
                            isLoading={isLoading}
                        />
                    </Box>
                </FormContainer>
            </MainContainer>
        </PageStructure>
    );
};

export default CodeVerificationStep;
