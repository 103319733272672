import { IconButton } from '@mui/material';
import React from 'react';
import { BsArrowLeft as ReturnIcon } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

type Props =
    | {
          returnToUrl: string;
      }
    | {
          logout: () => void;
      };

const BackButton = (props: Props) => {
    const navigate = useNavigate();

    const handleReturn = () => {
        if ('returnToUrl' in props) {
            navigate(`/${props.returnToUrl}`);
        } else if ('logout' in props) {
            props.logout();
        }
    };

    return (
        <IconButton
            onClick={handleReturn}
            aria-label="return"
            disableRipple
            sx={{
                border: 'none',
                '&:focus-visible': {
                    outline: '1px solid #292D32',
                },
            }}
        >
            <ReturnIcon size={24} color="#292D32" />
        </IconButton>
    );
};

export default BackButton;
