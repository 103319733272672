import { useMediaQuery, useTheme } from '@mui/material';
import { NotificationBell, NovuProvider, PopoverNotificationCenter } from '@novu/notification-center';
import { usePermissions } from 'permissions/utils';
import React from 'react';

import { pxToRem } from 'components/theme/typography';

type Props = {
    subscriberId: string;
    subscriberHash: string;
};

export const NotificationCenter = ({ subscriberId, subscriberHash }: Props) => {
    const hasPermission = usePermissions();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    if (!hasPermission('Community', 'view-notification-center')) {
        return null;
    }

    return (
        <NovuProvider
            subscriberId={subscriberId}
            subscriberHash={subscriberHash}
            applicationIdentifier={process.env.REACT_APP_NOVU_APP_ID as string}
            styles={{
                bellButton: {
                    root: {
                        marginRight: pxToRem(12),
                        svg: isMobile
                            ? {
                                  color: '#ffffff',
                                  fill: '#ffffff1f',
                              }
                            : {
                                  color: '#006b75',
                                  fill: '#ffffff1f',
                              },
                    },
                },
                layout: {
                    root: isMobile
                        ? {
                              maxWidth: '100vw !important',
                          }
                        : {},
                },
            }}
        >
            <PopoverNotificationCenter colorScheme={'light'}>
                {({ unseenCount }) => <NotificationBell unseenCount={unseenCount} />}
            </PopoverNotificationCenter>
        </NovuProvider>
    );
};
