import { api } from 'api';

import { ResidentTasksResponse } from 'types/flowSheet';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { formatApiParams, useToken } from 'lib/common';

type FlowSheetDataParams = {
    branchId: number;
    startDate: string;
    endDate: string;
    residentId?: number;
};

export const useFlowSheetDataQuery = (params: FlowSheetDataParams) => {
    const token = useToken();
    return useErrorHandledQuery(
        ['export-flow-sheet', params],
        async () => {
            const {
                data: { response },
            } = await api.get<{ response: ResidentTasksResponse }>(`/tasks/flow-sheet-data`, {
                headers: { Authorization: token },
                params: formatApiParams(params),
            });
            return response;
        },
        { enabled: false }
    );
};
