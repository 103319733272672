import { Box, Button, Link, Typography } from '@mui/material';
import momentTz from 'moment-timezone';
import Pluralize from 'pluralize';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { RewardTypeDialogData } from 'types/myRewards';

import { useClaimPointsMutation, useRewardsDetailsQuery } from 'api/queries/caregiverRewards';
import { pxToRem } from 'components/theme/typography';
import PointItem from 'pages/MyRewards/home/PointItem';

import { RewardTypeDetailsDialog } from './RewardTypeDetailsDialog';
import { REWARD_TYPE_DETAILS_DIALOG_DATA } from './constants';

type Props = {
    userId: number;
    branchId: number;
};

const LastShiftPointsSummary = ({ userId, branchId }: Props) => {
    const { isLoading: rewardsDetailsIsLoading, data: rewardsDetailsData } = useRewardsDetailsQuery(userId, branchId);

    const [rewardTypeDialogData, setRewardTypeDialogData] = useState<RewardTypeDialogData | null>(null);

    const { mutate: claimPointsMutation } = useClaimPointsMutation(userId, branchId);

    if (rewardsDetailsIsLoading || !rewardsDetailsData) {
        return null;
    }

    const documentationCompliancePoints =
        rewardsDetailsData.documentationCompliancePoints.unclaimedPoints +
        rewardsDetailsData.documentationCompliancePoints.claimedPoints;

    const shiftFinalizeDate = momentTz(rewardsDetailsData.shiftFinalizeDateUtc);

    let documentationCompliancePending = false;
    if (
        (rewardsDetailsData.shiftType === 'CURRENT' && documentationCompliancePoints === 0) ||
        (momentTz().isBefore(shiftFinalizeDate) && documentationCompliancePoints === 0)
    ) {
        documentationCompliancePending = true;
    }

    const checkInPoints =
        rewardsDetailsData.checkInPoints.unclaimedPoints + rewardsDetailsData.checkInPoints.claimedPoints;

    const unclaimedPointTotal =
        rewardsDetailsData.documentationCompliancePoints.unclaimedPoints +
        rewardsDetailsData.residentNotesPoints.unclaimedPoints +
        rewardsDetailsData.unscheduledTasksPoints.unclaimedPoints +
        rewardsDetailsData.checkInPoints.unclaimedPoints +
        rewardsDetailsData.previousUnclaimedPoints;

    const claimedPointTotal =
        rewardsDetailsData.documentationCompliancePoints.claimedPoints +
        rewardsDetailsData.residentNotesPoints.claimedPoints +
        rewardsDetailsData.unscheduledTasksPoints.claimedPoints +
        rewardsDetailsData.checkInPoints.claimedPoints;

    const claimPointsOnClick = () => claimPointsMutation();

    const unscheduledTaskPoints =
        rewardsDetailsData.unscheduledTasksPoints.unclaimedPoints +
        rewardsDetailsData.unscheduledTasksPoints.claimedPoints;

    const residentNotesPoints =
        rewardsDetailsData.residentNotesPoints.unclaimedPoints + rewardsDetailsData.residentNotesPoints.claimedPoints;

    return (
        <>
            <Box
                sx={{
                    borderBottom: '1px solid #DEDEE0',
                    paddingBottom: pxToRem(24),
                    px: pxToRem(10),
                    marginTop: {
                        xs: pxToRem(24),
                        lg: pxToRem(32),
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: pxToRem(16),
                        color: '#2C2D3B',
                        fontWeight: 700,
                        fontSize: pxToRem(14),
                    }}
                >
                    <Typography
                        sx={{
                            fontWeight: 700,
                            fontSize: { xs: pxToRem(14), md: pxToRem(16) },
                        }}
                    >
                        {rewardsDetailsData.shiftType === 'LAST'
                            ? 'Points earned last shift'
                            : 'Points earned this shift'}
                    </Typography>
                    <Link component={RouterLink} to="history" sx={{ color: '#006B75' }}>
                        See history
                    </Link>
                </Box>
                <Box
                    sx={{
                        display: 'grid',
                        gap: 1,
                    }}
                >
                    <PointItem
                        titlePrefix="Earn Points"
                        title="by Submitting PRN Tasks"
                        subtitle={`${Pluralize('point', unscheduledTaskPoints, true)} (${Pluralize('PRNs', rewardsDetailsData.unscheduledTasksData.unscheduledTasksCount, true)})`}
                        openRewardTypeDetailsDialog={() => {
                            setRewardTypeDialogData({
                                type: 'unscheduled-tasks',
                                data: REWARD_TYPE_DETAILS_DIALOG_DATA.UNSCHEDULED_TASKS_RECORDED,
                            });
                        }}
                        highlightBackground={true}
                    />
                    <PointItem
                        titlePrefix="Earn Points"
                        title="by Submitting Notes"
                        subtitle={`${Pluralize('point', residentNotesPoints, true)} (${Pluralize('Note', rewardsDetailsData.residentNotesData.residentNotesCount, true)})`}
                        openRewardTypeDetailsDialog={() => {
                            setRewardTypeDialogData({
                                type: 'resident-notes',
                                data: REWARD_TYPE_DETAILS_DIALOG_DATA.RESIDENT_NOTES_SHARED,
                            });
                        }}
                        highlightBackground={true}
                    />
                    <PointItem
                        titlePrefix="100%"
                        title={REWARD_TYPE_DETAILS_DIALOG_DATA.DOCUMENTATION_ON_TIME.title}
                        subtitle={
                            documentationCompliancePending
                                ? `points will finalize within ${shiftFinalizeDate.fromNow(true)} from now`
                                : Pluralize('point', documentationCompliancePoints, true)
                        }
                        openRewardTypeDetailsDialog={() => {
                            setRewardTypeDialogData({
                                type: 'documentation-compliance',
                                data: REWARD_TYPE_DETAILS_DIALOG_DATA.DOCUMENTATION_ON_TIME,
                            });
                        }}
                    />
                    <PointItem
                        title={REWARD_TYPE_DETAILS_DIALOG_DATA.ON_TIME_CHECK_IN.title}
                        subtitle={Pluralize('point', checkInPoints, true)}
                        openRewardTypeDetailsDialog={() => {
                            setRewardTypeDialogData({
                                type: 'on-time-check-in',
                                data: REWARD_TYPE_DETAILS_DIALOG_DATA.ON_TIME_CHECK_IN,
                            });
                        }}
                    />
                    {rewardsDetailsData.previousUnclaimedPoints > 0 && (
                        <Typography
                            sx={{
                                textAlign: 'center',
                                fontSize: { xs: pxToRem(14) },
                                color: '#6F6F79',
                                mt: pxToRem(8),
                            }}
                        >
                            <Typography
                                component="span"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: { xs: pxToRem(14) },
                                }}
                            >
                                {rewardsDetailsData.previousUnclaimedPoints}
                            </Typography>{' '}
                            Unclaimed Points From Earlier Shifts
                        </Typography>
                    )}
                    <Button
                        color="primary"
                        disabled={unclaimedPointTotal === 0}
                        sx={{
                            width: '100%',
                            maxWidth: pxToRem(400),
                            fontSize: pxToRem(14),
                            fontWeight: 700,
                            paddingTop: pxToRem(10),
                            paddingBottom: pxToRem(10),
                            paddingLeft: pxToRem(24),
                            paddingRight: pxToRem(24),
                            marginTop: pxToRem(12),
                            justifySelf: 'center',
                            '&:disabled': {
                                backgroundColor: '#C7C8CB',
                            },
                        }}
                        onClick={claimPointsOnClick}
                    >
                        {unclaimedPointTotal > 0 ? (
                            <Box>
                                {`Claim All ${unclaimedPointTotal} ${Pluralize('point', unclaimedPointTotal)}`}
                                {claimedPointTotal > 0 && (
                                    <Box
                                        sx={{
                                            fontSize: pxToRem(12),
                                            fontWeight: 300,
                                        }}
                                    >
                                        {`(${claimedPointTotal} ${Pluralize(
                                            'point',
                                            claimedPointTotal
                                        )} already claimed)`}
                                    </Box>
                                )}
                            </Box>
                        ) : (
                            <>
                                {claimedPointTotal > 0 && (
                                    <Box>
                                        {`All ${claimedPointTotal} ${Pluralize(
                                            'point',
                                            claimedPointTotal
                                        )} Already Claimed`}
                                    </Box>
                                )}
                                {claimedPointTotal === 0 && <Box>No Points To Claim Yet</Box>}
                            </>
                        )}
                    </Button>
                </Box>
            </Box>
            {rewardTypeDialogData && (
                <RewardTypeDetailsDialog
                    isOpen={!!rewardTypeDialogData}
                    onClose={() => setRewardTypeDialogData(null)}
                    rewardTypeDialogData={rewardTypeDialogData}
                />
            )}
        </>
    );
};

export default LastShiftPointsSummary;
