import { SelectStructure } from 'types/inputs';

export const TIME_WIDTH = 72;
export const RESIDENT_PHOTO_WIDTH = 56;
export const CHECKBOX_WIDTH = 64;

export const EARLY_IN_SHIFT_MINUTES = 20;

export const TASKS_STATUSES = ['Completed', 'Dismissed', 'Canceled'];

export const TASKS_STATUSES_OPTIONS: SelectStructure[] = TASKS_STATUSES.map((status) => ({
    label: status,
    value: status,
}));
