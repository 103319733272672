import { Box, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Legend,
    Rectangle,
    RectangleProps,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from 'recharts';

import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';
import { Section, StandardDataset } from 'pages/OperationsDashboard/constants';
import { setSelectedStartDate } from 'redux/actions/opsDashboard';

import CustomBarChartTooltip from './CustomBarChartTooltip';
import { useChartClick } from './helpers';

interface CustomBarChartProps {
    data: StandardDataset[];
    data2?: StandardDataset[];
    section: Section;
}

const prepareChartData = (data: StandardDataset[]) =>
    data.map((dataset) => ({
        period: dataset.startDate,
        value: dataset.value,
    }));

type Props = RectangleProps & {
    dataKey?: string;
    period?: string;
    selectedStartDate?: string;
};

const CustomBar = (props: Props) => {
    const { fill, x, y, width, height, onMouseOver, onMouseOut, dataKey, period, selectedStartDate } = props;
    const [hovered, setHovered] = useState(false);

    const isSelected = period === selectedStartDate;

    const handleMouseOver = (e: React.MouseEvent<SVGRectElement, MouseEvent>) => {
        setHovered(true);
        if (onMouseOver) {
            onMouseOver(e);
        }
    };

    const handleMouseOut = (e: React.MouseEvent<SVGRectElement, MouseEvent>) => {
        setHovered(false);
        if (onMouseOut) {
            onMouseOut(e);
        }
    };

    const hoveredFill = dataKey === 'value2' ? '#FA9C7A' : '#006B75';

    return (
        <Rectangle
            x={x}
            y={y}
            width={width}
            height={height}
            fill={hovered || isSelected ? hoveredFill : fill}
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            cursor="pointer"
        />
    );
};

const CustomBarChart = ({ data, data2, section }: CustomBarChartProps) => {
    const isMobile = useMediaQuery('(max-width:1024px)');

    const { selectedStartDate } = useSelector((state: ReduxStore) => state.opsDashboard);

    let chartData = prepareChartData(data);

    if (data2) {
        chartData = chartData.map((dataset, index) => ({
            ...dataset,
            value2: data2[index].value,
        }));
    }

    const legendFormatter = (value: string) => {
        const legendMap = {
            value: 'Without ADL changes',
            value2: 'With ADL changes',
        };
        return <span style={{ color: '#6F6F79' }}>{legendMap[value]}</span>;
    };

    const isChangeOfCondition = section === 'CHANGE_OF_CONDITION';

    const handlePointClick = useChartClick(setSelectedStartDate);

    return (
        <Box width={1} height={{ xs: pxToRem(158), lg: pxToRem(336) }}>
            <ResponsiveContainer width="99%" height="100%">
                <BarChart data={chartData} onClick={handlePointClick}>
                    <CartesianGrid strokeDasharray="4 4" strokeOpacity={0.5} />
                    <XAxis
                        dataKey="period"
                        fontSize={isMobile ? 9 : 10}
                        fontFamily="Open Sans"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={7}
                        tickCount={8}
                        tickFormatter={(date: string) => {
                            const [, month, day] = date.split('-');
                            return `${month}/${day}`;
                        }}
                    />
                    <YAxis
                        fontSize={isMobile ? 10 : 12}
                        fontFamily="Open Sans"
                        color="#6F6F79"
                        axisLine={false}
                        tickLine={false}
                        tickMargin={isMobile ? 3 : 6}
                        width={isMobile ? 25 : 35}
                    />
                    <Tooltip content={<CustomBarChartTooltip />} cursor={false} />
                    {isChangeOfCondition && (
                        <Legend
                            iconSize={8}
                            iconType="circle"
                            wrapperStyle={{
                                fontSize: '11px',
                                fontFamily: 'Open Sans',
                                color: '#6F6F79',
                                lineHeight: 1.35,
                            }}
                            formatter={legendFormatter}
                        />
                    )}
                    <Bar
                        dataKey="value"
                        stackId={isChangeOfCondition ? 'a' : undefined}
                        fill="#C2DBDE"
                        barSize={isMobile ? 10 : 20}
                        shape={<CustomBar selectedStartDate={selectedStartDate} />}
                    />
                    {data2 && (
                        <Bar
                            dataKey="value2"
                            stackId="a"
                            fill="#FEE7DF"
                            barSize={isMobile ? 10 : 20}
                            shape={<CustomBar dataKey="value2" selectedStartDate={selectedStartDate} />}
                        />
                    )}
                </BarChart>
            </ResponsiveContainer>
        </Box>
    );
};

export default CustomBarChart;
