import { Box, Divider, Drawer, ListItem, ListItemButton, ListItemText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { GoSignOut } from 'react-icons/go';
import { useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { ReduxStore } from 'types/redux';

import { pxToRem } from 'components/theme/typography';
import { useAppDispatch } from 'constants/redux';
import { getInitials } from 'lib/common';
import { signOut } from 'redux/actions/session';
import { toggleMobileDrawer } from 'redux/actions/ui';

import {
    DRAWER_OPTIONS,
    DrawerAvatar,
    DrawerGreetingBox,
    DrawerGreetingContainer,
    DrawerList,
    DrawerListItemIcon,
    DrawerOptions,
    DrawerPrivacyLink,
    DrawerSupportListItem,
    MOBILE_DRAWER_WIDTH,
    getDrawerGreeting,
} from './shared';

const DrawerContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        display: 'flex',
        flexDirection: 'column',
        width: pxToRem(MOBILE_DRAWER_WIDTH),
        height: '100%',
        pt: 'env(safe-area-inset-top)',
    })
);

const MobileHeaderDrawer = () => {
    const { hardShutdown } = useIntercom();

    const { firstName, lastName, picture: userPicture } = useSelector((state: ReduxStore) => state.session.sessionData);
    const { isMobileDrawerOpen } = useSelector((state: ReduxStore) => state.ui);
    const dispatch = useAppDispatch();

    const userFullName = `${firstName} ${lastName}`;
    const userInitials = getInitials(firstName, lastName);

    const handleToggleDrawer = () => dispatch(toggleMobileDrawer());

    const handleSignOut = () => {
        hardShutdown(); // Reset Intercom settings on web since it is shared across users
        void dispatch(signOut());
    };

    return (
        <Drawer anchor="left" open={isMobileDrawerOpen} onClose={handleToggleDrawer}>
            <DrawerContainer role="presentation">
                <DrawerGreetingContainer>
                    <DrawerAvatar alt={userFullName} src={userPicture}>
                        {userInitials}
                    </DrawerAvatar>
                    <DrawerGreetingBox>
                        <Typography variant="subtitle1">{getDrawerGreeting()}</Typography>
                        <Typography variant="body2">{userFullName}</Typography>
                    </DrawerGreetingBox>
                </DrawerGreetingContainer>

                <DrawerList>
                    <DrawerOptions options={DRAWER_OPTIONS} />

                    <DrawerSupportListItem>
                        support@alliehealth.com
                        <DrawerPrivacyLink href="/privacy-policy" target="_blank">
                            Privacy
                        </DrawerPrivacyLink>
                    </DrawerSupportListItem>

                    <Divider />

                    <ListItem>
                        <ListItemButton onClick={handleSignOut}>
                            <DrawerListItemIcon>
                                <GoSignOut size={24} />
                            </DrawerListItemIcon>
                            <ListItemText primary="Sign Out" />
                        </ListItemButton>
                    </ListItem>
                </DrawerList>
            </DrawerContainer>
        </Drawer>
    );
};

export default MobileHeaderDrawer;
