import {
    ArrowBack as ArrowBackAndroidIcon,
    ArrowBackIos as ArrowBackIosIcon,
    Menu as MenuIcon,
} from '@mui/icons-material';
import { Box, IconButton, Paper, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { usePermissions } from 'permissions/utils';
import React from 'react';
import { isIOS } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useRouteHandle } from 'router';

import { ReduxStore } from 'types/redux';

import { useRewardsSummaryQuery } from 'api/queries/caregiverRewards';
import { NotificationCenter } from 'components/Shared/NotificationCenter';
import { GoldCoin } from 'components/Svg/GoldCoin';
import { pxToRem } from 'components/theme/typography';
import { useAppDispatch } from 'constants/redux';
import { toggleMobileDrawer } from 'redux/actions/ui';

import { HeaderTitle } from './shared';

const HeaderContainer = styled(Paper)(({ theme }) =>
    theme.unstable_sx({
        color: '#fff',
        bgcolor: '#006B75',
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        pt: 'env(safe-area-inset-top)',
        pr: pxToRem(8),
        boxShadow: 'none',
        borderRadius: 0,
        zIndex: 10,
    })
);

const RewardsContainer = styled(Box)(({ theme }) =>
    theme.unstable_sx({
        color: '#FCD7B1',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        gap: pxToRem(4),
        px: pxToRem(8),
        pt: pxToRem(2),
        pb: pxToRem(4),
        border: '1px solid white',
        borderRadius: pxToRem(48),
    })
);

const MobileHeaderDrawer = () => {
    const navigate = useNavigate();
    const { title, backOne, backTo } = useRouteHandle();

    const BackIcon = isIOS ? ArrowBackIosIcon : ArrowBackAndroidIcon;

    const { userId, branchId, novuSubscriberId } = useSelector((state: ReduxStore) => state.session.sessionData);
    const dispatch = useAppDispatch();

    const hasPermission = usePermissions();

    const { data: rewardsSummaryData } = useRewardsSummaryQuery(
        hasPermission('Community', 'read-reward') ? userId : undefined,
        branchId
    );

    const handleGoBackClick = () => {
        if (backOne) navigate(-1);
        else if (backTo) navigate(backTo, { replace: true });
    };

    const handleToggleDrawer = () => dispatch(toggleMobileDrawer());

    return (
        <HeaderContainer>
            {backOne || backTo ? (
                <IconButton size="large" onClick={handleGoBackClick} color="inherit">
                    <BackIcon />
                </IconButton>
            ) : (
                <IconButton size="large" onClick={handleToggleDrawer} color="inherit">
                    <MenuIcon />
                </IconButton>
            )}

            <HeaderTitle variant="subtitle1">{title}</HeaderTitle>

            {hasPermission('Community', 'update-reward') && (
                <RewardsContainer onClick={() => navigate('/rewards', { replace: true })}>
                    <GoldCoin size={20} viewBox="0 0 20 20" />
                    <Typography variant="caption" sx={{ fontSize: pxToRem(14) }}>
                        {rewardsSummaryData?.totalPoints ?? ''}
                    </Typography>
                </RewardsContainer>
            )}

            <NotificationCenter subscriberId={String(userId)} subscriberHash={novuSubscriberId} />
        </HeaderContainer>
    );
};

export default MobileHeaderDrawer;
