import { api } from 'api';

import { AssignmentListResponse } from 'types/assignments';

import { useErrorHandledQuery } from 'hooks/useErrorHandledQuery';
import { useToken } from 'lib/common';

export const useAssignmentsQuery = (branchId?: number) => {
    const token = useToken();

    return useErrorHandledQuery(
        ['assignments', branchId],
        async () => {
            const { data } = await api.get<AssignmentListResponse>(`/assignments`, {
                headers: { Authorization: token },
                params: { branch_id: branchId },
            });

            return data.response;
        },
        { enabled: !!branchId }
    );
};
