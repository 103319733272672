import { Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';

import { pxToRem } from 'components/theme/typography';
import { AVATAR_WIDTH, BASE_RESIDENT_PHOTO_PATH } from 'constants/residents';
import { getInitials } from 'lib/common';

type Props = {
    backgroundColor?: string;
    fontColor?: string;
    firstName: string;
    lastName: string;
    fullName?: string;
    photo: string | null;
    size?: number;
    sx?: object;
};

// TODO: Do not use this for caregivers (AH-950) and rename to ResidentAvatar
export const CustomAvatar = (props: Props) => {
    const { palette } = useTheme();
    const {
        backgroundColor = palette.app.green.light,
        fontColor = palette.common.white,
        firstName,
        lastName,
        photo,
        sx,
        size = AVATAR_WIDTH,
    } = props;
    const fullName = `${firstName} ${lastName}`;
    const initials = getInitials(firstName, lastName);
    const photoPath = photo ? `${BASE_RESIDENT_PHOTO_PATH}/${photo}` : undefined;

    const avatarStyle = {
        color: fontColor,
        backgroundColor,
        width: pxToRem(size),
        height: pxToRem(size),
        fontSize: pxToRem(size * 0.5),
        ...sx,
    };

    return (
        <Avatar alt={fullName} src={photoPath} sx={avatarStyle}>
            {initials}
        </Avatar>
    );
};
